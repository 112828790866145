import React, { useEffect, useState } from "react";
import { tipos } from "../../helpers/datos";
import { Link, useParams } from "react-router-dom";
import { TabbedBox } from "../../components/TabbedBox/TabbedBox";
import "./especificaciones.css";
//Latex
import LatexImagePrincipal from "../../assets/especificaciones/latexHeader.webp";
import LatexBanner from "../../assets/especificaciones/latexBanner.webp";
import LatexSecondaryImage from "../../assets/especificaciones/latexSecondaryImage.webp";
import LatexSecondaryImage2 from "../../assets/especificaciones/latexSecondaryImage2.webp";
import LatexLogoHeaderDoodle from "../../assets/latexLogoHeaderDoodle.svg";
import BaldeLatex from "../../assets/baldes/baldeL.webp";
import LatexDoodle from "../../assets/latexDoodle.svg";
// Revestimiento
import WhiteBackgroud from "../../assets/whiteBackground.webp";
import RevestimientoTituloDoodleBlanco from "../../assets/revestimientoTituloDoodleBlanco.svg";
import RevestimeintoImagenPrincipal from "../../assets/especificaciones/revestimientoImagenPrincipal.webp";
import RevestimientoTitleDoodle from "../../assets/revestimientoTitleDoodle.svg";
import RevestimientoSecondaryImage from "../../assets/especificaciones/revestimientoSecondaryImage.webp";
import RevestimientoSecondaryImage2 from "../../assets/especificaciones/revestimientoSecondaryImage2.webp";
import BaldeRPT from "../../assets/baldes/baldeRPT.webp";
// Base Coat
import BaseCoatHeaderDoodle from "../../assets/especificaciones/doodles/baseCoatHeaderDoodle.svg";
import BaseCoatBanner from "../../assets/especificaciones/baseCoatBanner.jpg";
import BaldeBC from "../../assets/baldes/baldeBC.webp";
import BaseCoatImagenPrincipal from "../../assets/especificaciones/baseCoatImagePrincipal.jpg";
import BaseCoatSecondaryImage from "../../assets/especificaciones/baseCoatSecondaryImage.png";
import BaseCoatSecondaryImage2 from "../../assets/especificaciones/baseCoatSecondaryImage2.jpg";
// TECHOS MUROS
import BaldeTM from "../../assets/baldes/baldeTM.webp";
import TechosMurosBanner from "../../assets/especificaciones/techosMurosBanner.webp";
import TechosMurosHeaderDoodle from "../../assets/especificaciones/doodles/techosMurosHeaderDoodle.svg";
import TechosMurosImagenPrincipal from "../../assets/especificaciones/techosMurosImagePrincipal.webp";
import TechosMurosSecondaryImage from "../../assets/especificaciones/techosMurosSecondaryImage.webp";
import TechosMurosSecondaryImage2 from "../../assets/especificaciones/techosMurosSecondaryImage2.webp";
// GRANITO
import BaldeG from "../../assets/baldes/baldeG.webp";
import GranitoBanner from "../../assets/especificaciones/granitoBanner.webp";
import GranitoHeaderDoodle from "../../assets/especificaciones/doodles/granitoHeaderDoodle.svg";
import GranitoSecondaryImage from "../../assets/especificaciones/granitoSecondaryImage.webp";
import GranitoSecondaryImage2 from "../../assets/especificaciones/granitoSecondaryImage2.webp";
import { TarjetaColor } from "../../components/TarjetaColor/TarjetaColor";
import { colores, coloresEspecificaciones } from "../../helpers/datos";
// BASE PLASTICA
import BaldeBP from "../../assets/baldes/baldeBP.webp";
import BasePlasticaBanner from "../../assets/especificaciones/basePlasticaBanner.webp";
import BasePlasticaHeaderDoodle from "../../assets/especificaciones/doodles/basePlasticaHeaderDoodle.svg";
import BasePlasticaSecondaryImage from "../../assets/especificaciones/basePlasticaSecondaryImage.webp";
import BasePlasticaSecondaryImage2 from "../../assets/especificaciones/basePlasticaSecondaryImage2.webp";
import BasePlasticaImagenPrincipal from "../../assets/especificaciones/basePlasticaImagenPrincipal.webp";
import cx from "classnames";

export const Especificaciones = () => {
  let { id } = useParams();
  const tipoActual = tipos[id];
  const [isLocationLatex, setIsLocationLatex] = useState(false);
  const [isLocationRevestimientos, setIsLocationRevestimientos] =
    useState(false);

  useEffect(() => {
    if (id === "0") {
      setIsLocationLatex(true);
    } else if (id === "1") {
      setIsLocationRevestimientos(true);
    }
  }, [id]);

  const data = [
    {
      title: function () {
        return (
          <>
            Latex
            <br />
            Profesional
          </>
        );
      },
      description: function () {
        return (
          <p>
            El Látex FullTex es una pintura de alta calidad y versatilidad que
            sobresale en el mundo de las pinturas gracias a sus características
            excepcionales, entre ellas, su asombroso poder cubritivo. Esta
            pintura a base de agua proporciona una excelente cobertura en una
            sola capa, lo que la convierte en una opción muy eficiente para
            proyectos de pintura. Gracias a su alta concentración de pigmentos y
            a su fórmula especialmente diseñada, el Látex FullTex  es capaz de
            ocultar fácilmente imperfecciones, manchas y colores previos en la
            superficie a pintar. Su poder cubritivo asegura que la pintura se
            adhiera de manera uniforme y logre un acabado impecable y duradero.{" "}
            <br />
            El gran poder cubritivo del Látex Fulltex permite ahorrar tiempo y
            esfuerzo en la aplicación, ya que con menos capas se obtiene un
            resultado satisfactorio. Además, al requerir menos pintura para
            cubrir una superficie, también se traduce en un ahorro económico. En
            resumen, el Látex Fulltex no solo ofrece una calidad excepcional y
            versatilidad, sino que su impresionante poder cubritivo lo convierte
            en una opción preferida por profesionales y aficionados a la
            pintura, proporcionando resultados superiores y un acabado impecable
            con un menor esfuerzo y consumo de material
          </p>
        );
      },
      banner: LatexBanner,
      imagePrincipal: LatexImagePrincipal,
      imageSecondary: LatexSecondaryImage,
      imageSecondary2: LatexSecondaryImage2,
      titleDoodle: LatexDoodle,
      logoHeaderDoodle: LatexLogoHeaderDoodle,
      balde: BaldeLatex,
    },
    {
      title: function () {
        return (
          <>
            Revestimiento
            <br />
            Plastico texturado
          </>
        );
      },
      description: function () {
        return (
          <p>
            El revestimiento plástico texturado es una solución versátil y
            duradera para proteger y embellecer superficies exteriores e
            interiores. Aplicado en capas, proporciona resistencia a la
            intemperie, impermeabilización y acabados decorativos. Su textura
            variada ofrece opciones estéticas que van desde lo rústico hasta lo
            moderno. Ideal para aplicaciones en fachadas, muros, y más, este
            revestimiento se destaca por su durabilidad y fácil mantenimiento.
            Reemplaza el revoque fino y la pintura en un solo paso
          </p>
        );
      },
      banner: WhiteBackgroud,
      imagePrincipal: RevestimeintoImagenPrincipal,
      imageSecondary: RevestimientoSecondaryImage,
      imageSecondary2: RevestimientoSecondaryImage2,
      titleDoodle: RevestimientoTitleDoodle,
      logoHeaderDoodle: RevestimientoTituloDoodleBlanco,
      balde: BaldeRPT,
    },
    {
      title: function () {
        return (
          <>
            <b>MEMBRANA LIQUIDA</b>
            <br />
            Poliuretanica
          </>
        );
      },
      description: function () {
        return (
          <>
            <p>
              La Base Niveladora <b>FULLTEX</b> Impermeable desarrollada por
              nuestros expertos químicos es una solución revolucionaria que
              ofrece una protección y nivelación excepcional en cualquier tipo
              de superficie. Su capacidad para cubrir y proteger materiales como
              cemento, ladrillo, vidrio, madera, cerámicos, azulejos y chapas
              metálicas, la convierte en una opción versátil y altamente
              eficiente para múltiples aplicaciones. Una de las ventajas más
              destacadas es su habilidad para tratar rajaduras que no se
              expanden en la superficie, lo que contribuye a evitar mayores
              daños y a mantener la integridad estructural. Al aplicar esta base
              niveladora y dejarla secar, se crea una capa impermeable que
              protege las superficies de la humedad y el paso del tiempo.
            </p>
            <p>
              Además, una vez que la Base niveladora <b>FULLTEX</b> esté seca,
              se vuelve el lienzo perfecto para aplicar cualquier producto
              Fulltex. Esto brinda la posibilidad de elegir entre una amplia
              gama de revestimientos plásticos para paredes, asegurando
              resultados duraderos, estéticos y de alta calidad.
            </p>
            <p>
              El uso de la <b>Base Niveladora FullTex</b> Impermeable no solo
              ahorra tiempo y esfuerzo al preparar diferentes superficies para
              el revestimiento, sino que también evita costosas reparaciones y
              proporciona una solución completa para embellecer y proteger
              cualquier espacio.
            </p>
            <p>
              Esta <b>Base Niveladora Fulltex</b> Impermeable es una verdadera
              innovación en el campo de los revestimientos plásticos, brindando
              múltiples beneficios como su capacidad para cubrir y proteger
              diversos materiales, reparar rajaduras y servir como base perfecta
              para la aplicación de productos FullTex. Con esta solución
              versátil y de alto rendimiento, los usuarios pueden transformar y
              proteger cualquier superficie con facilidad, logrando resultados
              profesionales y duraderos que impactarán positivamente en
              cualquier proyecto de diseño y decoración
            </p>
          </>
        );
      },
      banner: BaseCoatBanner,
      imagePrincipal: BaseCoatImagenPrincipal,
      imageSecondary: BaseCoatSecondaryImage,
      imageSecondary2: BaseCoatSecondaryImage2,
      titleDoodle: null,
      logoHeaderDoodle: BaseCoatHeaderDoodle,
      balde: BaldeBC,
    },
    {
      title: function () {
        return (
          <>
            <b>MEMBRANA LIQUIDA</b>
            <br />
            Poliuretanica
          </>
        );
      },
      description: function () {
        return (
          <>
            <p>
              La membrana líquida de FullTech, una innovación de Fulltex, ofrece
              una solución eficaz y duradera para impermeabilizar superficies.
              Esta membrana forma una capa protectora flexible y resistente
              sobre diferentes materiales, previniendo filtraciones de agua y
              protegiendo contra la humedad y la corrosión. Su aplicación fácil
              y rápida la hace ideal para una variedad de proyectos de
              construcción y renovación, garantizando un acabado de alta calidad
              y una protección confiable en cualquier superficie.
            </p>
          </>
        );
      },
      banner: TechosMurosBanner,
      imagePrincipal: TechosMurosImagenPrincipal,
      imageSecondary: TechosMurosSecondaryImage,
      imageSecondary2: TechosMurosSecondaryImage2,
      titleDoodle: null,
      logoHeaderDoodle: TechosMurosHeaderDoodle,
      balde: BaldeTM,
    },
    {
      title: function () {
        return (
          <>
            <b>PIEDRA GRANITO</b>
            <br />
            Revestimiento texturizado
          </>
        );
      },
      description: function () {
        return (
          <>
            <p>
              El Revestimiento <b>Piedra Granito FullTex</b>es una opción
              excepcional para embellecer y proteger paredes tanto en interiores
              como exteriores. Su característica distintiva radica en que está
              compuesto por auténticas piedras naturales de granito, lo que lo
              convierte en una elección especialmente valorada en el mundo de
              los revestimientos para paredes.
            </p>
            <p>
              Este revestimiento simula con gran realismo la apariencia de una
              pared de granito natural, brindando un aspecto genuino, natural y
              sofisticado a las superficies. La variedad en las formas y tamaños
              de las piedras, así como sus tonalidades y vetas características,
              añaden un encanto único a los espacios. Una de las principales
              ventajas del Revestimiento Piedra <b>Granito FullTex</b> es la
              durabilidad y resistencia excepcional que ofrece. Al tratarse de
              piedras naturales de granito, este revestimiento puede soportar
              condiciones climáticas adversas, la exposición al sol, la humedad
              y el desgaste diario sin perder su aspecto original ni su
              integridad.
            </p>
            <p>
              Además, aunque este revestimiento se compone de piedras naturales,
              su aplicación se facilita mediante la utilización de adhesivos y
              técnicas adecuadas, lo que permite obtener resultados
              satisfactorios sin complicaciones, incluso para aquellos que no
              son expertos en el área.
            </p>
            <p>
              En resumen, el Revestimiento Piedra <b>Granito FullTex</b> es una
              elección perfecta para aquellos que deseen el auténtico encanto
              del granito natural en sus paredes. Su composición con piedras
              genuinas, sumada a su resistencia y facilidad de aplicación, lo
              convierten en una opción ideal para transformar cualquier espacio
              en un ambiente distinguido y acogedor con la belleza y elegancia
              propias del granito.
            </p>
          </>
        );
      },
      banner: GranitoBanner,
      imagePrincipal: GranitoBanner,
      imageSecondary: GranitoSecondaryImage,
      imageSecondary2: GranitoSecondaryImage2,
      titleDoodle: null,
      logoHeaderDoodle: GranitoHeaderDoodle,
      balde: BaldeG,
    },
    {
      title: function () {
        return (
          <>
            <b>PIEDRA GRANITO</b>
            <br />
            Revestimiento texturizado
          </>
        );
      },
      description: function () {
        return (
          <>
            <p>
              La pintura <b>FONDO BASE FULLTEX</b> es un componente esencial en
              el proceso de aplicación de revestimientos texturados en
              superficies, ya que proporciona una base adecuada y uniforme para
              asegurar el máximo rendimiento y durabilidad del recubrimiento
              final. Esta pintura específica se destaca por sus características
              técnicas, diseñadas para preparar la superficie y permitir una
              adhesión óptima del revestimiento texturado, garantizando un
              acabado resistente y estético.
            </p>
            <p>
              Una de las principales cualidades de la pintura{" "}
              <b>FONDO BASE FULLTEX</b> es su alta capacidad de relleno de
              imperfecciones en la superficie. Con su formulación especial,
              puede nivelar pequeñas irregularidades, grietas y porosidades,
              asegurando una superficie uniforme y lisa para el posterior
              revestimiento texturado. Esto también ayuda a mejorar la
              adherencia del revestimiento, evitando posibles desprendimientos y
              fisuras a lo largo del tiempo.
            </p>
            <p>
              Además de su función niveladora, la pintura{" "}
              <b>FONDO BASE FULLTEX</b> posee excelentes propiedades de sellado.
              Al aplicarse sobre la superficie, crea una barrera protectora que
              evita que la humedad y otros agentes externos penetren en la
              estructura, protegiendo así tanto el material de la superficie
              como el propio revestimiento texturado.
            </p>
            <p>
              La pintura <b>FONDO BASE FULLTEX</b> es altamente resistente a la
              intemperie y los cambios climáticos, lo que contribuye a la
              longevidad del revestimiento texturado. Su capacidad para soportar
              condiciones ambientales adversas, como altas temperaturas, lluvia
              o exposición a los rayos UV, asegura un acabado duradero y de
              aspecto impecable a lo largo del tiempo.
            </p>
            <p>
              Para facilitar su aplicación, la pintura <b>FONDO BASE FULLTEX</b>{" "}
              suele tener una consistencia adecuada, lo que permite un fácil
              extendido y un secado rápido. Además, su formulación está diseñada
              para ser compatible con una amplia gama de revestimientos
              texturados, lo que brinda flexibilidad al usuario para elegir el
              acabado deseado.
            </p>
          </>
        );
      },
      banner: BasePlasticaBanner,
      imagePrincipal: BasePlasticaImagenPrincipal,
      imageSecondary: BasePlasticaSecondaryImage,
      imageSecondary2: BasePlasticaSecondaryImage2,
      titleDoodle: null,
      logoHeaderDoodle: BasePlasticaHeaderDoodle,
      balde: BaldeBP,
    },
  ];

  const tabbedBoxData = [
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>Caracteristicas</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  LATEX PROFESIONAL FULLTEX es una pintura al látex, fabricada
                  en base a resinas en dispersión acuosa que proporciona un
                  acabado liso mate ideal para la protección ybelleza de todo
                  tipo de paredes interiores y exteriores.
                </p>
                <ul>
                  <li>Con aditivos antihongos.</li>
                  <li>Fácil aplicación y tiempo de secado óptimo.</li>
                  <li>Buen poder cubritivo.</li>
                  <li>Textura lisa mate.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>Superficie</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  LATEX PROFESIONAL puede aplicarse sobre revoque fino,
                  hormigón, yeso, placas de yeso, maderas, empapelados
                  pintables, ladrillos, etc. En todos los casos la superficie
                  debe estar limpia y seca, libre de gratitud y polvillo, sin
                  partes flojas y sin humedad visible.
                </p>
                <p>
                  En caso de manchas de algas u hongos, lavar la superficie con
                  una solución 1:10 de agua lavandina, enjuagando cuidadosamente
                  al final (tomar la precaución de usar guantes y anteojos de
                  seguridad, evitando el contacto con la piel y mucosas); y
                  dejando secar muy bien antes de proceder al pintado. En caso
                  de partes flojas eliminarlas con espátula, luego lijar y
                  aplicar una mano de FIJADOR/SELLADOR.
                </p>
                <p>
                  En caso de desear superficies mas parejas y de mayor belleza
                  aplicar 1 o 2 manos de ENDUIDO INTERIOR.
                </p>
              </>
            );
          },
        },
        {
          id: 2,
          title: function () {
            return (
              <>
                <p>03 //</p> <p>Aplicación</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Aplicar dos a tres manos de LATEX PROFESIONAL con pincel,
                  rodillo o soplete, tal cual se suministra o a lo sumo diluido
                  con 5 a 10 % de agua de buena calidad. Dejar secar de 4 a 6
                  horas entre manos para obtener la mayor performance del
                  producto. Recomendaciones
                  <br />
                  Antes de comenzar enmascarar las superficies lindantes ya
                  terminadas próximas al área de trabajo. No aplicar si se
                  preveen lluvias cercanas. No aplicar el producto sobre
                  superficies muy calientes, tales como paredes muy expuestas al
                  sol, recomendando aplicar en estos casos a primeras horas de
                  la mañana. No aplicar con temperatura ambiente menor a 3° C o
                  mayor a 35° C. No aplicar con Humedad Relativa Ambiente (HRA)
                  mayor al 80%. Lavar las herramientas y las superficies
                  contaminadas antes de que el producto se seque y utilizando
                  agua y un detergente
                </p>
              </>
            );
          },
        },
        {
          id: 3,
          title: function () {
            return (
              <>
                <p>04 //</p> <p>Tiempo de Secado</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Secado superficial (al tacto): 1 hora.
                  <br /> Secado final: 3 a 5 días Dependiendo de las condiciones
                  ambientales. Durante este lapso es aconsejable cuidar al
                  máximo la superficie a fin de alargar su vida útil.
                  <br />
                  Rendimientos:
                  <br />
                  El rendimiento de <b>LÁTEX FULLTEX PROFESIONAL</b> es de 10 a
                  12metros cuadrados por litro y por mano, dependiendo del tipo
                  de superficie a pintar.
                </p>
              </>
            );
          },
        },
        {
          id: 4,
          title: function () {
            return (
              <>
                <p>05 //</p> <p>Presentación</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  <b>LATEX PROFESIONAL FULLTEX INTERIOR/EXTERIOR</b> se envasa
                  en Baldes Plásticos de 4 litros
                  <br />
                  Baldes Plásticos de 10 litros
                  <br />
                  Baldes Plásticos de 20 litros.
                  <br /> Colores
                  <br />
                  Blanco, entonable con entonadores universales hasta 30 cc por
                  litro como máximo.
                </p>
              </>
            );
          },
        },
      ],
      design: "primary",
    },
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>Caracteristicas</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Revestimiento a llana Fulltex es un revestimiento plástico
                  formulado en base a resinas acrílicas de alta elasticidad y
                  resistencia a los factores climáticos, cargas minerales
                  granulométricamente calibradas que otorgan aspecto pétreo,
                  aditivos promotores de adherencia y aditivos hidrorepelentes.
                </p>
                <ul>
                  <li>Apto tanto para interiores como para exteriores</li>
                  <li>Lavable e Impermeable</li>
                  <li>Muy resistente a climas rigurosos</li>
                  <li>Elástico. Cubre micro fisuras evitando su reaparición</li>
                  <li>
                    Dos tipo de texturas (Fina / Media) que admiten un amplio
                    surtido de terminaciones
                  </li>
                  <li>
                    Listo para usar. Fácil aplicación. Disimula imperfecciones.
                  </li>
                  <li>
                    Reemplaza en una sola operación revoque fino, enduido,
                    fijador y pintura, reduciendo costos de materiales y mano de
                    obra.{" "}
                  </li>
                </ul>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>Datos Técnicos</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Peso Especifico: 1,79 +- 0,02 g/cm3
                  <br />
                  Viscosidad: 120.000 CPS Spindle 7 RPM 20 a 25°C.
                </p>
              </>
            );
          },
        },
        {
          id: 2,
          title: function () {
            return (
              <>
                <p>03 //</p> <p>Superficie</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  REVESTIMIENTO TEXTURADO puede aplicarse sobre revoque fino,
                  grueso fratasado, hormigón, enduido, placas de yeso,
                  fibrocemento, madera, premoldeado, etc. Todas las superficies
                  deben estar limpias, libres de grasitud y polvillo, sin partes
                  flojas y sin humedad visible. En caso de manchas de algas u
                  hongos lavar la superficie con una solución 10% de agua
                  lavandina (tomar la precaución de utilizar guantes y anteojos
                  de seguridad al realizar la limpieza), enjuagando
                  cuidadosamente al final y dejando secar 1 o 2 días antes de
                  aplicar el revestimiento. En caso de partes flojas quitarlas
                  con espátula y lijar. En caso de revoques recién hechos
                  (vírgenes) siempre es recomendable que transcurran al menos 15
                  días (recomendable 35 a 45 días) entre la finalización de los
                  mismos y la aplicación del revestimiento.
                </p>
              </>
            );
          },
        },
        {
          id: 3,
          title: function () {
            return (
              <>
                <p>04 //</p> <p>Aplicación</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Aplicar con rodillo de lana de pelo corto una mano de BASE
                  COLOR pura o diluida con hasta 5% de agua de buena
                  calidad, dejando secar de 4 a 5 horas.Se debe colocar tanto
                  para aplicación a llana  como a rodillo. Esta mano de Base
                  otorga:
                </p>
                <ul>
                  <li>Cubritivo de fondo al revestimiento.</li>
                  <li>
                    Aumento de la hidrorepelencia plastificando las superficies.
                  </li>
                  <li>
                    Óptimos niveles de adherencia entre el sustrato y el
                    posterior revestimiento.
                  </li>
                </ul>
                <p>
                  Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2
                  presentaciones (Fino/Media) utilizando una llana metálica
                  perfectamente limpia para extender el producto. La llana se
                  debe colocar a 45° respecto de la pared y el grosor de la capa
                  a aplicar dependerá del tipo de textura utilizada. Una vez que
                  el material comience a secar, esto es aproximadamente en 5 a
                  10 minutos dependiendo de las condiciones ambientales,
                  fratasar únicamente con llana plástica, sin presionar
                  demasiado, y en forma vertical, circular, etc. Según la
                  terminación deseada. Es importante mantener limpia la llana
                  para evitar arrastres de material. REVESTIMIENTO TEXTURADO A
                  RODILLO 
                  <br />
                  Una vez aplicado el BASE COLOR comenzar aplicar la primera
                  mano de revestimiento a rodillo, en este caso se debe utilizar
                  un rodillo de lana de pelo corto la aplicación debe ser de
                  arriba hacia abajo de manera lineal y pareja.Se debe aplicar
                  poca presión al rodillo para se pueda lograr la textura
                  deseada.Una vez secado la primer mano de revestimiento,
                  comenzar el mismo proceso con la segunda mano.
                </p>
              </>
            );
          },
        },
        {
          id: 4,
          title: function () {
            return (
              <>
                <p>05 //</p> <p>Recomendaciones</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  REVESTIMIENTO TEXTURADO puede aplicarse sobre revoque fino,
                  grueso fratasado, hormigón, enduido, placas de yeso,
                  fibrocemento, madera, premoldeado, etc. Todas las superficies
                  deben estar limpias, libres de grasitud y polvillo, sin partes
                  flojas y sin humedad visible. En caso de manchas de algas u
                  hongos lavar la superficie con una solución 10% de agua
                  lavandina (tomar la precaución de utilizar guantes y anteojos
                  de seguridad al realizar la limpieza), enjuagando
                  cuidadosamente al final y dejando secar 1 o 2 días antes de
                  aplicar el revestimiento. En caso de partes flojas quitarlas
                  con espátula y lijar. En caso de revoques recién hechos
                  (vírgenes) siempre es recomendable que transcurran al menos 15
                  días (recomendable 35 a 45 días) entre la finalización de los
                  mismos y la aplicación del revestimiento.
                </p>
              </>
            );
          },
        },
        {
          id: 5,
          title: function () {
            return (
              <>
                <p>06 //</p> <p>Rendimientos</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  REVESTIMIENTO TEXTURADO puede aplicarse sobre revoque fino,
                  grueso fratasado, hormigón, enduido, placas de yeso,
                  fibrocemento, madera, premoldeado, etc. Todas las superficies
                  deben estar limpias, libres de grasitud y polvillo, sin partes
                  flojas y sin humedad visible. En caso de manchas de algas u
                  hongos lavar la superficie con una solución 10% de agua
                  lavandina (tomar la precaución de utilizar guantes y anteojos
                  de seguridad al realizar la limpieza), enjuagando
                  cuidadosamente al final y dejando secar 1 o 2 días antes de
                  aplicar el revestimiento. En caso de partes flojas quitarlas
                  con espátula y lijar. En caso de revoques recién hechos
                  (vírgenes) siempre es recomendable que transcurran al menos 15
                  días (recomendable 35 a 45 días) entre la finalización de los
                  mismos y la aplicación del revestimiento.
                </p>
              </>
            );
          },
        },
      ],
      design: "primary",
    },
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>MODO DE APLICACION</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Preparación de la superficie: Asegúrate de que la superficie
                  esté limpia, seca y libre de polvo, grasa u otras impurezas.
                  Si hay fisuras o rajaduras que no se expanden, utiliza la
                  masilla para reparación para cerrarlas y nivelar la
                  superficie. Deja secar y lija suavemente si es necesario.
                </p>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>Primer mano</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Con la espátula o llana, aplica la primera capa de Base
                  Niveladora Impermeable sobre la superficie en movimientos
                  uniformes y en una sola dirección. Cubre todas las áreas que
                  requieran nivelación y protección. Deja que esta primera capa
                  seque según las indicaciones del fabricante.
                </p>
              </>
            );
          },
        },
        {
          id: 2,
          title: function () {
            return (
              <>
                <p>03 //</p> <p>Lijado (opcional)</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Si es necesario, lija suavemente la superficie una vez que la
                  primera capa haya secado, para obtener una superficie más lisa
                  y uniforme. Limpia el polvo resultante con un paño limpio.
                </p>
              </>
            );
          },
        },
        {
          id: 3,
          title: function () {
            return (
              <>
                <p>04 //</p> <p>Segunda capa</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Aplica una segunda capa de Base Niveladora Impermeable de la
                  misma manera que la primera, asegurándote de cubrir
                  completamente la superficie y las áreas reparadas. Esta
                  segunda capa ayudará a nivelar la superficie de manera óptima
                  y a garantizar la impermeabilidad.
                </p>
              </>
            );
          },
        },
        {
          id: 4,
          title: function () {
            return (
              <>
                <p>05 //</p> <p>Acabado final</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Una vez que la segunda capa haya secado, realiza ajustes o
                  detalles adicionales si es necesario para lograr un acabado
                  perfecto y nivelado. Revestimiento con productos FullTex: Una
                  vez que la Base Niveladora Impermeable esté completamente seca
                  y la superficie esté nivelada y protegida, estará lista para
                  ser revestida con cualquier producto FullTex. Gracias a su
                  formulación especial y versátil, la Base Niveladora es
                  compatible con todos los productos de la línea FullTex.
                  <br />
                  La Base Niveladora Impermeable proporciona un lienzo perfecto
                  para la aplicación de los productos Fulltex, asegurando una
                  adherencia óptima y resultados profesionales y duraderos. Con
                  esta combinación, podrás transformar y embellecer cualquier
                  superficie con facilidad, logrando un acabado de alta calidad
                  que destacará en cualquier proyecto de diseño y decoración.
                </p>
              </>
            );
          },
        },
      ],
      design: "primary",
    },
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>Caracteristicas</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  <b>Membrana Liquida FullTex</b> es una masa plástica,
                  formulada en base polímeros acrílicos flexibles e impermeables
                  de alta resistencia a los factores climáticos, pigmentos
                  reflectantes de la luz, cargas minerales de alta dureza, y
                  aditivos especiales promotores de adherencia, hidrorepelencia
                  y nivelación.
                </p>
                <ul>
                  <li>
                    Confiere un aspecto agradable a la cubierta de techos.
                  </li>
                  <li>Pasta blanda. Fácil de aplicar, aún en frío.</li>
                  <li>
                    Forma un film elástico continuo, resistente al agua, a la
                    intemperie y a los rayos UV.
                  </li>
                  <li>
                    Por su elasticidad sella las micro fisuras, y absorbe su
                    movimiento, evitando que estas reaparezcan.
                  </li>
                  <li>
                    Es tixotrópico, por lo que también puede aplicarse con
                    facilidad en superficies verticales.
                  </li>
                  <li>
                    No forma barrera de vapor, por lo que a diferencia de los
                    asfaltos, permite la eliminación de humedad de los techos.
                  </li>
                  <li>
                    Es apto para transito común, presentando una aceptable
                    resistencia al desgaste con un alto grado de flexibilidad.
                  </li>
                  <li>En color blanco es un eficaz aislante térmico.</li>
                  <li>Resistente a la formación de hongos.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>Superficie</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  <b>Membrana Liquida FullTex</b> está recomendada para
                  impermeabilizar todo tipo de superficies, tanto verticales
                  como horizontales. Altamente indicado para cubiertas de
                  techados tanto cementicios como cerámicos, tejas,
                  fibrocemento, etc. Todas las superficies deben estar limpias,
                  libres de gratitud y polvillo, sin partes flojas y sin humedad
                  visible.
                </p>
                <p>
                  En caso de manchas de algas u hongos lavar la superficie con
                  una solución 10% de agua lavandina (tomar la precaución de
                  utilizar guantes y anteojos de seguridad al realizar la
                  limpieza), enjuagando cuidadosamente al final y dejando secar
                  1 o 2 días antes de aplicar el revestimiento. En caso de
                  carpetas recién hechas (vírgenes) siempre es recomendable que
                  transcurran al menos 15 días (recomendable 30 a 45 días) entre
                  la finalización de las mismas y la aplicación del
                  revestimiento.
                </p>
                <p>
                  <b>Membrana Liquida FullTex</b> un revestimiento que forma una
                  película plástica de alto espesor, no es recomendado aplicarlo
                  sobre superficies que contengan humedad previa, ya que esto
                  puede producir ampollado del film.
                </p>
              </>
            );
          },
        },
        {
          id: 2,
          title: function () {
            return (
              <>
                <p>03 //</p>
                <p>Aplicación</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Mezclar la pintura hasta homogeneizar color y viscosidad.
                  Aplicar con rodillo o pincel una primera mano
                  de Membrana diluida con 10 a 20 % de agua de buena calidad,
                  dejando secar de 4 a 6 horas para lograr una perfecta
                  impregnación de la superficie. (a modo de imprimación),
                  haciendo especial hincapié en zonas con fisuras. Una vez seca
                  la imprimación aplicar 2 a 4 manos mas del producto sin diluir
                  (o a lo sumo diluido con hasta 5 % de agua para facilitar su
                  aplicación), dejando transcurrir de 6 a 12 horas entre mano y
                  mano.
                </p>
                <p>
                  <b>Membrana Liquida FullTex</b> puede aplicarse a pincel,
                  rodillo, pistola airless, o espatulado; cuidando de que los
                  espesores aplicados en cada mano no superen los 400-500
                  micrones. Es aconsejable aplicar las distintas manos en forma
                  cruzada, para favorecer el entrelazado de las diferentes capas
                  plásticas y garantizar una cobertura uniforme.
                </p>
                <p>
                  Si se desea aumentar la resistencia mecánica y a la tracción
                  del film, se aconseja colocar una o dos capas de tela de
                  poliéster o lana de vidrio sintética (geotextil), solapadas y
                  cruzadas, pegándolas con la segunda y tercera mano del
                  producto, y aplicando una mano final a modo de terminación.
                  Este mismo procedimiento se puede efectuar puntualmente en
                  grietas o fisuras.
                </p>
                <p>
                  <b>RECOMENDACIONES</b>
                  <br /> Antes de comenzar enmascarar las superficies lindantes
                  ya terminadas próximas al área de trabajo. Se recomienda
                  comenzar el trabajo con los paños mas pequeños para encontrar
                  los tiempos de trabajo y la textura deseada, para luego hacer
                  extensivo los mismos a paños mayores. No aplicar si se preveen
                  lluvias cercanas.
                </p>
              </>
            );
          },
        },
        {
          id: 3,
          title: function () {
            return (
              <>
                <p>04 //</p> <p>Tiempo de Secado</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Secado superficial (al tacto):
                  <br /> 6 a 12 horas
                </p>
                <p>
                  Secado final:
                  <br />5 a 7 días Dependiendo de las condiciones ambientales.
                  Durante este lapso es aconsejable cuidar al máximo la
                  superficie a fin de alargar su vida útil.
                </p>
                <p>
                  Rendimientos
                  <br /> El rendimiento de la membrana liquida Fulltex es de
                  1.000 kilo por metro cuadrado, en 4 manos (terminado).
                </p>
              </>
            );
          },
        },
        {
          id: 4,
          title: function () {
            return (
              <>
                <p>05 //</p> <p>Presentación</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Se envasa en Baldes Plásticos de 4 Litros, 10 Litros y 20
                  Litros. El producto se comercializa en color Arena, Blanco,
                  Gris, Rojo Teja, Beige, Gris Acero, Negro y Verde. El color
                  blanco puede entonarse con entonadores universales hasta un
                  máximo de 30 cc de entonador por cada kilo de Membrana
                  Liquida.
                </p>
              </>
            );
          },
        },
      ],
      design: "primary",
    },
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>PRESENTACIÓN Y RENDIMIENTO</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Los 30 kilos de Piedra Granito tienen un rendimiento de 10
                  mts2 de aplicación en paredes donde la superficie es pareja.
                  Aquellas paredes que no estén parejas o a plomo, el
                  rendimiento se puede ver afectado.
                </p>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>MODO DE APLICACIÓN</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Aplicar REVESTIMIENTO TEXTURADO PIEDRA GRANITO una vez
                  aplicado el FONDO BASE COLOR. Dejar esperar 24 hs de secado o
                  hasta que esté seca al tacto.
                </p>
                <p>
                  Con una llana metálica, planchar el material de manera pareja
                  y constante. Tratando de evitar dejar sin cargar la pared a
                  medida que la llana esté aplicando el material. Cubrir de
                  manera pareja toda la superficie de la pared. Una vez que haya
                  comenzado a secar el material, repasar TODA la pared con la
                  llana metálica limpia para darle mejor terminación al
                  producto.
                </p>
                <p>
                  EL tiempo de secado total rondará las entre las 6 y 8 horas.
                  En días donde la humedad de ambiente sea mayor, el tiempo de
                  secado llevará más tiempo. Nuestro REVESTIMIENTO TEXTURADO
                  PIEDRA GRANITO se puede aplicar en paredes de cemento,
                  durlock, yeso, fibrofacil, maderas. SUGERENCIAS DE APLICACIÓN:
                  <br />* NO aplicar en días donde exista pronósticos de lluvia.
                  <br />* NO aplicar en días donde la temperatura sea menor a 3°
                  o mayor a 40°.
                  <br />* NO aplicar con humedad relativa ambiente (HRA) mayor
                  al 80%.
                  <br />* Lavar las herramientas y las superficies contaminadas
                  antes de que el producto se seque. Utilizando agua y
                  detergente.
                </p>
              </>
            );
          },
        },
      ],
      design: "secondary",
    },
    {
      data: [
        {
          id: 0,
          title: function () {
            return (
              <>
                <p>01 //</p> <p>MODO DE APLICACIÓN</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <p>
                  Sugerimos una espera 24 hs entre la aplicación del Fondo Base
                  FULLTEX y la aplicación del Revestimiento plástico texturado.
                </p>
              </>
            );
          },
        },
        {
          id: 1,
          title: function () {
            return (
              <>
                <p>02 //</p> <p>PRESENTACIÓN Y RENDIMIENTO</p>
              </>
            );
          },
          description: function () {
            return (
              <>
                <ul>
                  <li>Base x 20 litros: 100 mts2</li>
                  <li>Base x 10 litros: 50 mts2</li>
                </ul>
              </>
            );
          },
        },
      ],
      design: "secondary",
    },
  ];

  const coloresId = [[], [], [], [0, 1, 2, 3, 4, 5, 6, 7], [8, 9, 10], []];

  const tabbedBoxContainerClassName = cx("especificaionesTabbed", {
    secondaryEspecificaionesTabbed: tabbedBoxData[id]?.design === "secondary",
  });

  return (
    <div className="especificacionesContainer">
      <div className="banner">
        <div className="headingBanner">
          <div
            className="backgroundBanner"
            style={{ backgroundImage: `url(${data[id].banner})` }}
          />
          <div className="title">
            <img src={data[id].logoHeaderDoodle} alt="LatexLogoHeaderDoodle" />
          </div>
          <div
            className="baldeContainer"
            style={{ backgroundImage: `url(${data[id].balde})` }}
          ></div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <div
            className="imagePrincipal"
            style={{ backgroundImage: `url(${data[id].imagePrincipal})` }}
          >
            {data[id].titleDoodle && (
              <img src={data[id].titleDoodle} alt="principalImage" />
            )}
          </div>
          <div className="imageSecondaryContainer">
            <div
              style={{ backgroundImage: `url(${data[id].imageSecondary})` }}
              alt="secondaryImage"
              className="imageSecondary"
            />
            <div
              style={{ backgroundImage: `url(${data[id].imageSecondary2})` }}
              className="imageSecondary"
              alt="secondaryImage2"
            />
          </div>
          {isLocationLatex && (
            <div className="nuestrosColoresContainer">
              <Link to={`/coloresLatex`} className="nuestrosColores">
                Nuestros Colores
              </Link>
            </div>
          )}
          {isLocationRevestimientos && (
            <div className="nuestrosColoresContainer">
              <Link to={`/coloresRevestimiento`} className="nuestrosColores">
                Nuestros Colores
              </Link>
            </div>
          )}
        </div>
        <div className="right">
          <h1>{data[id].title()}</h1>
          {data[id].description()}
          <div className="buttonsInMobile">
            <Link to={`/contacto`} className="consultar">Consultar</Link>
            {isLocationLatex && (
              <Link to={`/coloresLatex`} className="nuestrosColores">
                Nuestros Colores
              </Link>
            )}
            {isLocationRevestimientos && (
              <Link to={`/coloresRevestimiento`} className="nuestrosColores">
                Nuestros Colores
              </Link>
            )}
          </div>
        </div>
      </div>
      {coloresId[id].length > 0 && (
        <>
          <h1 className="coloresTitulo">
            <span className="italic">COLORES</span>
            <br />
            DE LINEA
          </h1>
          <div className="coloresContainer">
            {coloresId[id].map(
              (id) =>
                coloresEspecificaciones[id] && (
                  <TarjetaColor
                    key={id}
                    colorData={coloresEspecificaciones[id]}
                  />
                )
            )}
          </div>
        </>
      )}
      <div className={tabbedBoxContainerClassName}>
        {console.log(tabbedBoxData[id])}
        <TabbedBox
          data={tabbedBoxData[id]?.data}
          design={tabbedBoxData[id]?.design}
        />
      </div>
    </div>
  );
};
