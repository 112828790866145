export const tipos = [
  {
    id: 0,
    tipo: "Revestimiento",
    imagenes: ["revestimiento1", "revestimiento2"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
      Llana Fina: 15/17 Metros cuadrados en 1 mano
      Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
      La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
      Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
      fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
      Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "revestimiento2",
    imagenTitleLogo: "revestimientoTitleLogo",
  },
  {
    id: 1,
    tipo: "Base Coat",
    imagenes: ["baseCoat1", "baseCoat2"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
    Llana Fina: 15/17 Metros cuadrados en 1 mano
    Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
    La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
    Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
    fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
    Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "baseCoat2",
    imagenTitleLogo: "baseCoatTitleLogo",
  },
  {
    id: 2,
    tipo: "Latex",
    imagenes: ["latex1", "latex2"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
    Llana Fina: 15/17 Metros cuadrados en 1 mano
    Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
    La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
    Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
    fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
    Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "latex1",
    imagenTitleLogo: "latexTitleLogo",
  },
  {
    id: 3,
    tipo: "Base Plastica",
    imagenes: ["basePlastica"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
    Llana Fina: 15/17 Metros cuadrados en 1 mano
    Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
    La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
    Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
    fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
    Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "basePlastica",
    imagenTitleLogo: "basePlasticaTitleLogo",
  },
  {
    id: 4,
    tipo: "Membrana Liquida",
    imagenes: ["membranaLiquida"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
    Llana Fina: 15/17 Metros cuadrados en 1 mano
    Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
    La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
    Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
    fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
    Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "membranaLiquida",
    imagenTitleLogo: "membranaLiquidaTitleLogo",
  },
  {
    id: 5,
    tipo: "Granito",
    imagenes: ["membranaLiquida"],
    textos: [
      {
        weight: "normal",
        text: "Revestimiento plastico texturado de 30 kilos, aplicar de forma uniforme en la pared previamente lisa y nivelada",
      },
      {
        weight: "normal",
        text: `Rendimiento Aplicación llana media: 10/11 metros cuadrados 
    Llana Fina: 15/17 Metros cuadrados en 1 mano
    Aplicación a Rodillo: 35 metros cuadrados en 1 mano ( se recomienda aplicar 2 manos para una mejor terminación )`,
      },
      {
        weight: "normal",
        text: `Aplicar REVESTIMIENTO TEXTURADO A LLANA en algunas de sus 2 presentaciones (Fino/Media) utilizando una llana metálica perfectamente limpia para extender el producto.
    La llana se debe colocar a 45° respecto de la pared y el grosor de la capa a aplicar dependerá del tipo de textura utilizada. 
    Una vez que el material comience a secar, esto es aproximadamente en 5 a 10 minutos dependiendo de las condiciones ambientales, 
    fratasar únicamente con llana plástica, sin presionar demasiado, y en forma vertical, circular, etc. Según la terminación deseada.
    Es importante mantener limpia la llana para evitar arrastres de material.`,
      },
    ],
    imagenEspecificaciones: "granito",
    imagenTitleLogo: "granitoTitleLogo",
  },
];
export const colores = [
  {
    id: "01",
    tipo: "Arena",
    nombre: "Clara",
    title: "Arena Clara",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "arenaClara",
    imagenCard: "cardArenaClara",
    white: false,
  },
  {
    id: "02",
    tipo: "Arena",
    nombre: "Luminoso",
    title: "Arena Luminoso",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "arenaLuminoso",
    imagenCard: "cardArenaLuminoso",
    white: false,
  },
  {
    id: "03",
    tipo: "Arena",
    nombre: "",
    title: "Arena",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "arena",
    imagenCard: "cardArena",
    white: false,
  },
  {
    id: "04",
    tipo: "Beige",
    nombre: "Almendra",
    title: "Beige Almendra",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "beigeAlmendra",
    imagenCard: "cardBeigeAlmendra",
    white: false,
  },
  {
    id: "05",
    tipo: "Beige",
    nombre: "Caramelo",
    title: "Beige Caramelo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "beigeCaramelo",
    imagenCard: "cardBeigeCaramelo",
    white: false,
  },
  {
    id: "06",
    tipo: "Beige",
    nombre: "Claro",
    title: "Beige Claro",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "beigeClaro",
    imagenCard: "cardBeigeClaro",
    white: false,
  },
  {
    id: "07",
    tipo: "Beige",
    nombre: "Verdoso",
    title: "Beige Verdoso",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "beigeVerdoso",
    imagenCard: "cardBeigeVerdoso",
    white: false,
  },
  {
    id: "08",
    tipo: "Blanco",
    nombre: "Nieve",
    title: "Blanco Nieve",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "blancoNieve",
    imagenCard: "cardBlancoNieve",
    white: false,
  },
  {
    id: "09",
    tipo: "Blanco",
    nombre: "",
    title: "Blanco",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "blanco",
    imagenCard: "cardBlanco",
    white: false,
  },
  {
    id: "10",
    tipo: "Canela",
    nombre: "",
    title: "Canela",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "canela",
    imagenCard: "cardCanela",
    white: false,
  },
  {
    id: "11",
    tipo: "Chocolate",
    nombre: "",
    title: "Chocolate",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "chocolate",
    imagenCard: "cardChocolate",
    white: true,
  },
  {
    id: "12",
    tipo: "Gris",
    nombre: "Hielo",
    title: "Gris Hielo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisHielo",
    imagenCard: "cardGrisHielo",
    white: false,
  },
  {
    id: "13",
    tipo: "Gris",
    nombre: "Intermedio",
    title: "Gris Intermedio",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisIntermedio",
    imagenCard: "cardGrisIntermedio",
    white: false,
  },
  {
    id: "14",
    tipo: "Gris",
    nombre: "Medio",
    title: "Gris Medio",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisMedio",
    imagenCard: "cardGrisMedio",
    white: true,
  },
  {
    id: "15",
    tipo: "Gris",
    nombre: "Oscuro",
    title: "Gris Oscuro",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisOscuro",
    imagenCard: "cardGrisOscuro",
    white: true,
  },
  {
    id: "16",
    tipo: "Gris",
    nombre: "Perlado",
    title: "Gris Perlado",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisPerlado",
    imagenCard: "cardGrisPerlado",
    white: true,
  },
  {
    id: "17",
    tipo: "Mandarina",
    nombre: "",
    title: "Mandarina",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "mandarina",
    imagenCard: "cardMandarina",
    white: true,
  },
  {
    id: "18",
    tipo: "Marfil",
    nombre: "",
    title: "Marfil",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "marfil",
    imagenCard: "cardMarfil",
    white: false,
  },
  {
    id: "19",
    tipo: "Marron",
    nombre: "Habano",
    title: "Marron Habano",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "marronHabano",
    imagenCard: "cardMarronHabano",
    white: true,
  },
  {
    id: "20",
    tipo: "Natural",
    nombre: "",
    title: "Natural",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "natural",
    imagenCard: "cardNatural",
    white: false,
  },
  {
    id: "21",
    tipo: "Negro",
    nombre: "",
    title: "Negro",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "negro",
    imagenCard: "cardNegro",
    white: true,
  },
  {
    id: "22",
    tipo: "Oliva",
    nombre: "",
    title: "Oliva",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "oliva",
    imagenCard: "cardOliva",
    white: true,
  },
  {
    id: "23",
    tipo: "Rojo",
    nombre: "Teja",
    title: "Rojo Teja",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "rojoTeja",
    imagenCard: "cardRojoTeja",
    white: true,
  },
  {
    id: "24",
    tipo: "Rojo",
    nombre: "",
    title: "Rojo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "rojo",
    imagenCard: "cardRojo",
    white: true,
  },
  {
    id: "25",
    tipo: "Rosa",
    nombre: "Nuevo",
    title: "Rosa Nuevo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "rosaNuevo",
    imagenCard: "cardRosaNuevo",
    white: false,
  },
  {
    id: "26",
    tipo: "Rosa",
    nombre: "Opaco",
    title: "Rosa Opaco",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "rosaOpaco",
    imagenCard: "cardRosaOpaco",
    white: true,
  },
  {
    id: "27",
    tipo: "Sesamo",
    nombre: "",
    title: "Sesamo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "sesamo",
    imagenCard: "cardSesamo",
    white: false,
  },
  {
    id: "28",
    tipo: "Tierra",
    nombre: "",
    title: "Tierra",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "tierra",
    imagenCard: "cardTierra",
    white: true,
  },
  {
    id: "29",
    tipo: "Tormenta",
    nombre: "",
    title: "Tormenta",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "tormenta",
    imagenCard: "cardTormenta",
    white: true,
  },
  {
    id: "30",
    tipo: "Trigo",
    nombre: "",
    title: "Trigo",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "trigo",
    imagenCard: "cardTrigo",
    white: false,
  },
  {
    id: "31",
    tipo: "Ceniza",
    nombre: "",
    title: "Ceniza",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    white: false,
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "ceniza",
    imagenCard: "cardCeniza",
    white: false,
  },
  {
    id: "32",
    tipo: "Verde",
    nombre: "Militar",
    title: "Verde Militar",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "verdeMilitar",
    imagenCard: "cardVerdeMilitar",
    white: true,
  },
  {
    id: "33",
    tipo: "Vison",
    nombre: "",
    title: "Vison",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "vison",
    imagenCard: "cardVison",
    white: true,
  },
  {
    id: "34",
    tipo: "Grison",
    nombre: "",
    title: "Grison",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grison",
    imagenCard: "cardGrison",
    white: true,
  },
  {
    id: "35",
    tipo: "Verde",
    nombre: "Claro",
    title: "Verde Claro",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "verdeClaro",
    imagenCard: "cardVerdeClaro",
    white: true,
  },
  {
    id: "36",
    tipo: "Camel",
    nombre: "",
    title: "Camel",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "camel",
    imagenCard: "cardCamel",
    white: true,
  },
  {
    id: "37",
    tipo: "Francia",
    nombre: "",
    title: "Francia",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: true,
    imagen: "francia",
    imagenCard: "cardFrancia",
    white: true,
  },
  {
    id: "38",
    tipo: "Durazno",
    nombre: "",
    title: "Durazno",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "durazno",
    imagenCard: "cardDurazno",
    white: true,
  },
  {
    id: "39",
    tipo: "Ocre",
    nombre: "",
    title: "Ocre",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "ocre",
    imagenCard: "cardOcre",
    white: true,
  },
];

export const coloresEspecificaciones = [
  {
    id: "1",
    color: "Arena",
    title: "Arena",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "arenaTM",
    white: false,
    design: "primary",
  },
  {
    id: "2",
    color: "Blanco",
    title: "Blanco",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "blancoTM",
    white: false,
    design: "primary",
  },
  {
    id: "3",
    color: "Gris",
    title: "Gris",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisTM",
    white: true,
    design: "primary",
  },
  {
    id: "4",
    color: "Rojo Teja",
    title: "Rojo Teja",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "rojoTejaTM",
    white: true,
    design: "primary",
  },
  {
    id: "5",
    color: "Beige",
    title: "Beige",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "beigeTM",
    white: false,
    design: "primary",
  },
  {
    id: "6",
    color: "Gris Acero",
    title: "Gris Acero",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisAceroTM",
    white: true,
    design: "primary",
  },
  {
    id: "7",
    color: "Negro",
    title: "Negro",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "negroTM",
    white: true,
    design: "primary",
  },
  {
    id: "8",
    color: "Verde",
    title: "Verde",
    subtitle: "MEMBRANA POLIURETANICA",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "verdeTM",
    white: true,
    design: "primary",
  },
  {
    id: "9",
    color: "Rosa Granito",
    title: "Rosa",
    subtitle: "Revestimiento Texturizado",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "rosaGR",
    white: false,
    design: "secondary",
  },
  {
    id: "10",
    color: "Negro Granito",
    title: "Negro",
    subtitle: "Revestimiento Texturizado",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "negroGR",
    white: false,
    design: "secondary",
  },
  {
    id: "11",
    color: "Gris Granito",
    title: "Gris",
    subtitle: "Revestimiento Texturizado",
    year: "2024",
    description: function () {
      return (
        <p>
          La membrana líquida de <b>FullTech</b>, una innovación de Fulltex,
          ofrece una solución eficaz y duradera para impermeabilizar
          superficies.
        </p>
      );
    },
    intenso: false,
    imagen: "grisGR",
    white: false,
    design: "secondary",
  },
];
