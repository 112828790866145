import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FormTextInput } from "../../components/FormTextInput/FormTextInput";
import { CarouselComponent } from "../../components/Carousel/Carousel";
import { FormCheckboxInput } from "../../components/FormChecboxInput/FormCheckboxInput";
import { ReactComponent as FranquiciaDoodle } from "../../assets/franquiciaDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import CarouselImage1 from "../../assets/carousel1.webp";
import CarouselImage2 from "../../assets/carousel2.webp";
import CarouselImage3 from "../../assets/carousel3.webp";
import { validator } from "../../helpers/utils";
import "react-toastify/dist/ReactToastify.css";
import "./franquiciaFulltex.css";

export const FranquiciaFulltex = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [localidadError, setLocalidadError] = useState("");
  const [dni, setDNI] = useState("");
  const [dniError, setDNIError] = useState("");
  const [rubro, setRubro] = useState("");
  const [rubroError, setRubroError] = useState("");
  const [conocesLocales, setConocesLocales] = useState();
  const [conocesLocalesError, setConocesLocalesError] = useState("");
  const [probasteProductos, setProbasteProductos] = useState();
  const [probasteProductosError, setProbasteProductosError] = useState("");
  const [tenesCapital, setTenesCapital] = useState();
  const [tenesCapitalError, setTenesCapitalError] = useState("");
  const [quieresAbrirFranquicia, setQuieresAbrirFranquicia] = useState();
  const [quieresAbrirFranquiciaError, setQuieresAbrirFranquiciaError] =
    useState("");

  const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3];

  const conocesAlgunLocalOptions = ["Si", "No"];
  const probasteAlgunProductoOptions = ["Si", "No"];
  const contasConCapitallOptions = ["Si", "No"];
  const queresAbrirFranquiciaOptions = [
    "Si",
    "No",
    "Tal vez",
    "Solo queria información",
  ];

  const validateInputs = () => {
    let isValid = true;
    if (validator(name, "name", setNameError, true)) isValid = false;
    if (validator(phone, "phone", setPhoneError, true)) isValid = false;
    if (validator(email, "email", setEmailError, true)) isValid = false;
    if (validator(localidad, "text", setLocalidadError, true)) isValid = false;
    if (validator(dni, "dni", setDNIError, true)) isValid = false;
    if (validator(rubro, "text", setRubroError, true)) isValid = false;
    if (validator(conocesLocales, "checkbox", setConocesLocalesError))
      isValid = false;
    if (validator(probasteProductos, "checkbox", setProbasteProductosError))
      isValid = false;
    if (validator(tenesCapital, "checkbox", setTenesCapitalError))
      isValid = false;
    if (
      validator(
        quieresAbrirFranquicia,
        "checkbox",
        setQuieresAbrirFranquiciaError
      )
    )
      isValid = false;

    return isValid;
  };

  const resetValues = () => {
    setName("");
    setPhone("");
    setEmail("");
    setLocalidad("");
    setDNI("");
    setRubro("");
    setConocesLocales();
    setProbasteProductos();
    setTenesCapital();
    setQuieresAbrirFranquicia();
  };

  const sendFormToBackend = () => {
    axios
      .post(
        "https://04kz73hhq3.execute-api.sa-east-1.amazonaws.com/franquicia_fulltex",
        {
          nombre: name,
          telefono: phone,
          email,
          localidad,
          dni,
          rubroActual: rubro,
          conocesAlgunLocal: conocesAlgunLocalOptions[conocesLocales] ?? "",
          probasteNuestrosProductos:
            probasteAlgunProductoOptions[probasteProductos] ?? "",
          contasConCapital: contasConCapitallOptions[tenesCapital] ?? "",
          quieresAbrirLocal:
            queresAbrirFranquiciaOptions[quieresAbrirFranquicia] ?? "",
        }
      )
      .then((res) => {
        toast.success("Formulario enviado");
        resetValues();
      })
      .catch((err) => {
        toast.error("Error al enviar el formulario");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      sendFormToBackend();
    }
  };

  return (
    <div className="franquiciaFulltexContainer">
      <div className="banner">
        <div className="headingBanner">
          <div className="title">
            <FranquiciaDoodle className="doodle" />
          </div>
          <div className="texts">
            <p>
              Gracias por tu interés en representar esta marca y los valores que
              queremos aportar en nuestro trabajo
            </p>
            <p>
              Nos complace saber que estás interesado en nuestra propuesta. Por
              ello, te solicitamos que llenes el formulario adjunto para que
              podamos evaluar tu <b>interés genuino</b> y así enviarte la
              invitación correspondiente.
            </p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="franquiciaInfo">
          <p>
            En nuestra empresa, valoramos a nuestros clientes. Nos esforzamos
            por brindar un servicio excepcional y tratar a cada cliente con el
            cuidado y la atención que se merecen. Nuestros productos están
            diseñados con los más altos estándares de calidad, garantizando
            durabilidad y satisfacción del cliente.
          </p>
          <p>
            La primera reunión con nuestro equipo se llevará a cabo en nuestras
            instalaciones en Zárate Provincia de Buenos Aires, donde podrás
            conocer nuestro proceso de fabricación y sentirte parte de nuestra
            familia desde el primer momento. Estamos emocionados de tener la
            oportunidad de trabajar juntos y esperamos poder ofrecerte una
            experiencia extraordinaria en cada paso del camino.
          </p>
          <p>
            Nuestro personal del departamento comercial, se estará comunicando
            telefónicamente para coordinar el día y horario
          </p>
          <div className="carouselSection">
            <CarouselComponent images={carouselImages} />
          </div>
        </div>
        <div className="franquiciaForm">
          <div className="headingText">
            <WorldDoodleBlack className="worldDoodle" />
            <p>
              Llena nuestro formulario y nos comunicaremos lo mas pronto
              posible!
            </p>
          </div>
          <div className="form">
            <FormTextInput
              label={"Nombre y Apellido"}
              mandatory={true}
              onChange={setName}
              value={name}
              name={"fullName"}
              inputError={nameError}
            />
            <FormTextInput
              label={"Telefono"}
              mandatory={true}
              onChange={setPhone}
              value={phone}
              name={"phone"}
              inputError={phoneError}
            />
            <FormTextInput
              label={"Mail"}
              mandatory={true}
              onChange={setEmail}
              value={email}
              name={"email"}
              inputError={emailError}
            />
            <FormTextInput
              label={
                "¿En que localidad o Provincia quieres vender nuestros productos?"
              }
              mandatory={true}
              onChange={setLocalidad}
              value={localidad}
              name={"localidad"}
              inputError={localidadError}
            />
            <FormTextInput
              label={"Documento de Identidad"}
              mandatory={true}
              onChange={setDNI}
              value={dni}
              name={"dni"}
              inputError={dniError}
            />
            <FormCheckboxInput
              label={"¿Conoces alguno de nuestros locales?"}
              items={conocesAlgunLocalOptions}
              onChange={setConocesLocales}
              name={"concocesAlgunLocal"}
              inputError={conocesLocalesError}
              checked={conocesLocales}
            />
            <FormCheckboxInput
              label={"¿Tuviste la oportunidad de probar nuestros productos?"}
              items={probasteAlgunProductoOptions}
              onChange={setProbasteProductos}
              name={"probasteAlgunProducto"}
              inputError={probasteProductosError}
              checked={probasteProductos}
            />
            <FormTextInput
              label={"¿Que rubro trabajas actualmente?"}
              value={rubro}
              onChange={setRubro}
              name={"rubro"}
              inputError={rubroError}
            />
            <FormCheckboxInput
              label={
                "¿Contas con capital para hacer una inversión de estas magnitudes?"
              }
              items={contasConCapitallOptions}
              onChange={setTenesCapital}
              name={"contasConCapital"}
              inputError={tenesCapitalError}
              checked={tenesCapital}
            />
            <FormCheckboxInput
              label={"¿Realmente quisieras abrir una franquicia fulltex?"}
              items={queresAbrirFranquiciaOptions}
              onChange={setQuieresAbrirFranquicia}
              name={"quieresAbrirFranquicia"}
              inputError={quieresAbrirFranquiciaError}
              checked={quieresAbrirFranquicia}
            />
            <br />
            <button
              type="submit"
              className="submitButton"
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
    </div>
  );
};
