import React from "react";
import { ReactComponent as LogoFulltexBlanco } from "../../assets/logoFulltexBlanco.svg";
import { ReactComponent as CursorLocationDoodle } from "../../assets/cursorLocationDoodle.svg";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.css";
import NodeTrademark from "../../assets/nodeTrademark.png";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="logoContainer">
          <LogoFulltexBlanco alt="logo" className="logo" />
          <div className="contacts">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            <p>+54 9 11 6361-3392 / +54 9 3487 67-7088</p>
          </div>
          <div className="contacts">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <p>fulltexrevestimientos@gmail.com</p>
          </div>
          <div className="contacts">
            <CursorLocationDoodle className="icon" />
            <div className="text">
              <p>Dirección</p>
              <p className="noirPro">Bernardo irigoyen 1692, Zárate</p>
            </div>
          </div>
        </div>
        <div className="navigation">
          <div className="noirPro left">
            <p>Navegacion</p>
            <p>Productos</p>
            <Link  to={"/sucursales"}><p>Sucursales</p></Link>
            <Link  to={"/cotizaTuProyecto"}><p>Cotiza tu Proyecto</p></Link>
            <Link  to={"/distribuidores"}><p>Distribuidores</p></Link>
            <Link  to={"/franquiciaFulltex"}><p>Franquicia Fulltex</p></Link>
            <Link  to={"/contacto"}><p>Contacto</p></Link>
          </div>
          <div className="right">
            <Link  to="/preguntasFrecuentes">
              <p className="noirPro">Preguntas Frecuentes</p>
            </Link>
            <Link  to={"/contacto"}><p className="noirPro">Atencion al Cliente</p></Link>
            <Link  to={"/sucursales"}><p className="noirPro">Franquicia y Distribudores</p></Link>
            <div className="contacts">
              <FontAwesomeIcon icon={faPhone} />
              <p>11 5401-4628</p>
            </div>
          </div>
        </div>
        <div className="socialsContainer">
          <p>Estamos conectados</p>
          <div className="socials">
            <FontAwesomeIcon
              className="socialIcon"
              icon={faFacebook}
              onClick={() =>
                window.open("https://www.facebook.com/FullTexRevestimientos/", "_blank")
              }
            />
            <FontAwesomeIcon
              className="socialIcon"
              icon={faInstagram}
              onClick={() =>
                window.open("https://www.instagram.com/fulltexrevestimientos/", "_blank")
              }
            />
            <FontAwesomeIcon
              className="socialIcon"
              icon={faLinkedin}
              onClick={() =>
                window.open("https://www.linkedin.com/in/leonardo-pacheco-175912300/", "_blank")
              }
            />
          </div>
        </div>
        <div className="copyright">
          <div className="links">
            <p>Política de Privacidad</p>
            <p>|</p>
            <p>Legales</p>
            <p>|</p>
            <p>Node Digital Studio 2023</p>
          </div>
          <img className="trademark" src={NodeTrademark} alt="nodeTrademark" />
        </div>
      </div>
    </>
  );
}
