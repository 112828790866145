import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FormTextInput } from "../../components/FormTextInput/FormTextInput";
import { CarouselComponent } from "../../components/Carousel/Carousel";
import { FormCheckboxInput } from "../../components/FormChecboxInput/FormCheckboxInput";
import { FormFileInput } from "../../components/FormFileInput/FormFileInput";
import { FormTextboxInput } from "../../components/FormTextboxInput/FormTextboxInput";
import { ReactComponent as CotizaTuProyectoDoodle } from "../../assets/especificaciones/doodles/cotizaTuProyectoDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import CarouselImage1 from "../../assets/carousel1.webp";
import CarouselImage2 from "../../assets/carousel2.webp";
import CarouselImage3 from "../../assets/carousel3.webp";
import { validator } from "../../helpers/utils";
import "./cotizaTuProyecto.css";
import "react-toastify/dist/ReactToastify.css";

export const CotizaTuProyecto = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [metrosCuadrados, setMetrosCuadrados] = useState();
  const [metrosCuadradosError, setMetrosCuadradosError] = useState("");
  const [textura, setTextura] = useState();
  const [texturaError, setTexturaError] = useState("");
  const [color, setColor] = useState();
  const [colorError, setColorError] = useState("");
  const [zona, setZona] = useState();
  const [zonaError, setZonaError] = useState("");
  const [imgFile, setImgFile] = useState([]);
  const [comentarios, setComentarios] = useState("");
  const [comentariosError, setComentariosError] = useState("");

  const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3];

  const metrosCuadradosARevestirOptions = [
    "Hasta 100mt2",
    "Más de 100mt2",
    "Más de 200mt2",
    "Más de 300mt2",
  ];
  const texturaAAplicarOptions = [
    "Textura media a llana",
    "Textura Fina",
    "Textura a Rodillo",
    "Revestimiento Granito",
  ];
  const cantidadDeColoresOptions = ["1 color", "2 colores", "Más de 2 colores"];
  const ZonaDeTrabajoOptions = [
    "Zona Norte",
    "Zona Sur",
    "Zona Oeste",
    "Capital Federal",
    "Interior del Pais",
  ];

  const validateInputs = () => {
    let isValid = true;
    if (validator(name, "name", setNameError, true)) isValid = false;
    if (validator(phone, "phone", setPhoneError, true)) isValid = false;
    if (validator(email, "email", setEmailError, true)) isValid = false;
    if (validator(metrosCuadrados, "checkbox", setMetrosCuadradosError))
      isValid = false;
    if (validator(textura, "checkbox", setTexturaError)) isValid = false;
    if (validator(color, "checkbox", setColorError)) isValid = false;
    if (validator(zona, "checkbox", setZonaError)) isValid = false;
    if (validator(comentarios, "comment", setComentariosError)) isValid = false;
    return isValid;
  };

  const resetValues = () => {
    setName("");
    setPhone("");
    setEmail("");
    setMetrosCuadrados([]);
    setTextura();
    setColor();
    setZona();
    setImgFile([]);
    setComentarios("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      sendFormToBackend();
      resetValues();
    }
  };

  const sendFormToBackend = () => {
    axios
      .post(
        "https://04kz73hhq3.execute-api.sa-east-1.amazonaws.com/cotiza_proyecto",
        {
          nombre: name,
          telefono: phone,
          email,
          metrosCuadrados:
            metrosCuadradosARevestirOptions[metrosCuadrados] ?? "",
          texturaAAplicar: texturaAAplicarOptions[textura] ?? "",
          cantidadDeColores: cantidadDeColoresOptions[color] ?? "",
          zonaDeTrabajo: ZonaDeTrabajoOptions[zona] ?? "",
          archivos: imgFile,
          comentarios,
        }
      )
      .then((res) => {
        toast.success("Formulario enviado");
        resetValues();
      })
      .catch((err) => {
        toast.error("Error al enviar el formulario");
      });
  };

  return (
    <div className="cotizaTuProyectoContainer">
      <div className="cotizaTuProyectoInfo">
        <div className="title">
          <CotizaTuProyectoDoodle className="doodle" />
        </div>
        <p>
          Contamos con mano de obra propia para la aplicación de nuestro
          material. Al contratar nuestro servicio de aplicación, nosotros
          podemos garantizar tanto la calidad de la aplicación, como así también
          podemos asegurar las cantidades necesarias de material, evitando así,
          sobrantes y faltantes de revestimiento en obra
        </p>
        <div className="carouselSection">
          <CarouselComponent images={carouselImages} />
        </div>
      </div>
      <div className="cotizaTuProyectoForm">
        <div className="headingText">
          <WorldDoodleBlack className="worldDoodle" />
          <p>
            Para ayudarte con tu proyecto y que todo este bien correspondido, te
            pedimos que por favor llenes el formulario que se encuentra{" "}
            <b>aca</b> abajo
          </p>
        </div>
        <div className="form">
          <FormTextInput
            label={"Nombre y Apellido"}
            mandatory={true}
            onChange={setName}
            value={name}
            name={"fullName"}
            inputError={nameError}
          />
          <FormTextInput
            label={"Telefono"}
            mandatory={true}
            onChange={setPhone}
            value={phone}
            name={"phone"}
            inputError={phoneError}
          />
          <FormTextInput
            label={"Mail"}
            mandatory={true}
            onChange={setEmail}
            value={email}
            name={"email"}
            inputError={emailError}
          />
          <FormCheckboxInput
            label={"Metros Cuadrados a Revestir"}
            items={metrosCuadradosARevestirOptions}
            onChange={setMetrosCuadrados}
            name={"Metros"}
            inputError={metrosCuadradosError}
            checked={metrosCuadrados}
          />
          <FormCheckboxInput
            label={"Textura a Aplicar"}
            items={texturaAAplicarOptions}
            onChange={setTextura}
            name={"Textura"}
            inputError={texturaError}
            checked={textura}
          />
          <FormCheckboxInput
            label={"Cantidad de Colores "}
            items={cantidadDeColoresOptions}
            onChange={setColor}
            name={"Colores"}
            inputError={colorError}
            checked={color}
          />
          <FormCheckboxInput
            label={"Zona de Trabajo"}
            items={ZonaDeTrabajoOptions}
            onChange={setZona}
            name={"Zona"}
            inputError={zonaError}
            checked={zona}
          />
          <br />
          <FormFileInput
            label={"Envíanos algunas fotos del trabajo a hacer (si las tienes)"}
            value={imgFile}
            onChange={setImgFile}
          />
          <FormTextboxInput
            label={"Comentarios"}
            value={comentarios}
            onChange={setComentarios}
            name={"comentarios"}
            inputError={comentariosError}
          />
          <button type="submit" className="submitButton" onClick={handleSubmit}>
            Enviar
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
    </div>
  );
};
