import React from "react";
import "./obras.css";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { Observer } from "gsap/Observer";
import {ReactComponent as ObrasDoodle} from "../../assets/doodleIcons/obrasDoodle.svg";
import Obra1 from "../../assets/obras img/img-1.webp";
import Obra2 from "../../assets/obras img/img-2.webp";
import Obra3 from "../../assets/obras img/img-3.webp";
import Obra4 from "../../assets/obras img/img-4.webp";
import Obra5 from "../../assets/obras img/img-5.webp";
import Obra6 from "../../assets/obras img/img-6.webp";
import Obra7 from "../../assets/obras img/img-7.webp";
import Obra8 from "../../assets/obras img/img-8.webp";
import Obra9 from "../../assets/obras img/img-9.webp";
import Obra10 from "../../assets/obras img/img-10.webp";
import Obra11 from "../../assets/obras img/img-11.webp";
import Obra12 from "../../assets/obras img/img-12.webp";
import Obra13 from "../../assets/obras img/img-13.webp";
import Obra14 from "../../assets/obras img/img-14.webp";
import Obra15 from "../../assets/obras img/img-15.webp";
import Obra16 from "../../assets/obras img/img-16.webp";
import Obra17 from "../../assets/obras img/img-17.webp";
import Obra18 from "../../assets/obras img/img-18.webp";
import Obra19 from "../../assets/obras img/img-19.webp";
import Obra20 from "../../assets/obras img/img-20.webp";
import Obra21 from "../../assets/obras img/img-21.webp";
import Obra22 from "../../assets/obras img/img-22.webp";
import Obra23 from "../../assets/obras img/img-23.webp";
import Obra24 from "../../assets/obras img/img-24.webp";
import Obra25 from "../../assets/obras img/img-25.webp";
import Obra26 from "../../assets/obras img/img-26.webp";
import Obra27 from "../../assets/obras img/img-27.webp";
import { ReactComponent as DoodleGroup } from "../../assets/slidingDoodle.svg";
import { verticalLoop } from "../../helpers/vertical-loop";
import { horizontalLoop } from "../../helpers/horizontal-loop";
import { isMobile } from "react-device-detect";

const imagenesObras = [
  {
    src: Obra1,
    alt: "obra 1",
    posicion: "izquierda",
  },
  {
    src: Obra2,
    alt: "obra 2",
    posicion: "derecha",
  },
  {
    src: Obra3,
    alt: "obra 3",
    posicion: "izquierda",
  },
  {
    src: Obra4,
    alt: "obra 4",
    posicion: "derecha",
  },
  {
    src: Obra5,
    alt: "obra 5",
    posicion: "izquierda",
  },
  {
    src: Obra6,
    alt: "obra 6",
    posicion: "derecha",
  },
  {
    src: Obra7,
    alt: "obra 7",
    posicion: "izquierda",
  },
  {
    src: Obra8,
    alt: "obra 8",
    posicion: "derecha",
  },
  {
    src: Obra9,
    alt: "obra 9",
    posicion: "izquierda",
  },
  {
    src: Obra10,
    alt: "obra 10",
    posicion: "derecha",
  },
  {
    src: Obra11,
    alt: "obra 11",
    posicion: "izquierda",
  },
  {
    src: Obra12,
    alt: "obra 12",
    posicion: "derecha",
  },
  {
    src: Obra13,
    alt: "obra 13",
    posicion: "izquierda",
  },
  {
    src: Obra14,
    alt: "obra 14",
    posicion: "derecha",
  },
  {
    src: Obra15,
    alt: "obra 15",
    posicion: "izquierda",
  },
  {
    src: Obra16,
    alt: "obra 16",
    posicion: "derecha",
  },
  {
    src: Obra17,
    alt: "obra 17",
    posicion: "izquierda",
  },
  {
    src: Obra18,
    alt: "obra 18",
    posicion: "derecha",
  },
  {
    src: Obra19,
    alt: "obra 19",
    posicion: "izquierda",
  },
  {
    src: Obra20,
    alt: "obra 20",
    posicion: "derecha",
  },
  {
    src: Obra21,
    alt: "obra 21",
    posicion: "izquierda",
  },
  {
    src: Obra22,
    alt: "obra 22",
    posicion: "derecha",
  },
  {
    src: Obra23,
    alt: "obra 23",
    posicion: "izquierda",
  },
  {
    src: Obra24,
    alt: "obra 24",
    posicion: "derecha",
  },
  {
    src: Obra25,
    alt: "obra 25",
    posicion: "izquierda",
  },
  {
    src: Obra26,
    alt: "obra 26",
    posicion: "derecha",
  },
  {
    src: Obra27,
    alt: "obra 27",
    posicion: "izquierda",
  },
];

gsap.registerPlugin(useGSAP, Observer);

export const Obras = () => {
  const convertNumberToPositive = (number) => {
    if (number < 0) {
      return number * -1;
    } else {
      return number;
    }
  };
  const convertNumberToNegative = (number) => {
    if (number > 0) {
      return number * -1;
    } else {
      return number;
    }
  };
  const tickSpeed = isMobile ? -0.01 : -0.08
  useGSAP(() => {
    const loop = verticalLoop(".imagen", {
      repeat: -1,
    });
    const loopHorizontal = horizontalLoop(".imagen-horizontal", {
      repeat: -1,
      reversed: true,
    });
    const loopHorizontalInvertido = horizontalLoop(
      ".imagen-horizontal-invertido",
      {
        repeat: -1,
        reversed: true,
      }
    );
    let slow = gsap.to(loop, { timeScale: 0, duration: 0.5 });
    let slowHorizontal = gsap.to(loopHorizontal, {
      timeScale: -0.5,
      duration: 0.5,
    });
    let slowHorizontalInvertido = gsap.to(loopHorizontalInvertido, {
      timeScale: 0.5,
      duration: 0.5,
    });

    // make the loop stopped initially.
    loop.timeScale(0);
    loopHorizontal.timeScale(-0.5);
    loopHorizontalInvertido.timeScale(0.5);

    // now use an Observer to listen to pointer/touch/wheel events and set the timeScale of the infinite looping timeline accordingly.
    Observer.create({
      target: ".slider-imagenes",
      type: "pointer,touch,wheel",
      wheelSpeed: tickSpeed,
      onChange: (self) => {
        loop.timeScale(
          Math.abs(self.deltaX) > Math.abs(self.deltaY)
            ? -self.deltaX
            : -self.deltaY
        ); // whichever direction is bigger

        slow.invalidate().restart(); // now decelerate
      },
    });
    Observer.create({
      target: ".slider-imagenes",
      type: "pointer,touch,wheel",
      wheelSpeed: -0.04,
      onChange: (self) => {
        loopHorizontal.timeScale(
          Math.abs(self.deltaX) > Math.abs(self.deltaY)
            ? convertNumberToNegative(-self.deltaX)
            : convertNumberToNegative(-self.deltaY)
        ); // whichever direction is bigger
        loopHorizontalInvertido.timeScale(
          Math.abs(self.deltaX) > Math.abs(self.deltaY)
            ? convertNumberToPositive(-self.deltaX)
            : convertNumberToPositive(-self.deltaY)
        ); // whichever direction is bigger
        slowHorizontal.invalidate().restart(); // now decelerate
        slowHorizontalInvertido.invalidate().restart(); // now decelerate
      },
    });
  });

  return (
    <>
      <div className="slider-imagenes">
        <div className="texto-principal-contenedor">
          <div className="textos-principales">
            <ObrasDoodle className="obrasDoodle" />
          </div>
          <div className="texto-secundario">
            <p>
              Algunos de los proyectos de los clientes que nos{" "}
              <p>eligieron para ser parte de su cambio y renovación.</p>{" "}
            </p>
          </div>
        </div>
        <div className="media-pantalla-contenedor">
          <div className="linea-contenedor">
            {[...Array(16)].map((e, i) => (
              <div className="imagen-horizontal">
                <DoodleGroup />
              </div>
            ))}
          </div>
          <div className="linea-contenedor">
          {
              [...Array(16)].map((e, i) => (
              <div className="imagen-horizontal-invertido">
                <DoodleGroup />
              </div>
              )
            )
            }
          </div>
          <div className="linea-contenedor">
            {[...Array(16)].map((e, i) => (
              <div className="imagen-horizontal">
                <DoodleGroup />
              </div>
            ))}
          </div>
          <div className="linea-contenedor">
            {[...Array(16)].map((e, i) => (
              <div className="imagen-horizontal-invertido">
                <DoodleGroup />
              </div>
            ))}
          </div>
        </div>
        {imagenesObras.map((item, idx) => (
          <div
            className={idx === 0 ? "img-contenedor primero" : "img-contenedor"}
            key={item.id}
          >
            <img
              src={item.src}
              alt={item.alt}
              className={`imagen ${item.posicion}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};
