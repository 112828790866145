import React from "react";
import { ReactComponent as WorldDoodle } from "../../assets/worldDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import classNames from "classnames";
import "./tarjetaColorOneToOne.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const TarjetaColorOneToOne = ({ colorData, isModal, setModalId }) => {
  const claseDatos = classNames({
    datos: true,
    borderColorBlanco: colorData.white,
  });

  const claseText = classNames({
    colorBlanco: colorData.white,
    borderColorBlanco: colorData.white,
  });

  const renderTarjeta = () => {
    return (
      <div className="tarjetaColorOneToOne">
        <div
          style={{ backgroundColor: `${colorData.color}` }}
          alt="color"
          className="backgroundColor"
        />
        {isModal && (
          <div className="closeContainer" onClick={() => setModalId(-1)}>
            <FontAwesomeIcon icon={faXmark} className="close" />
          </div>
        )}
        <div className="content">
          <div className="title">
            <h1 className={claseText}>{colorData.title()}</h1>
          </div>
          <div className="datosContainer">
            <div className={claseDatos}>
              <h2 className={claseText}>{colorData.subtitle}</h2>
              <h2 className={claseText}>{colorData.year}</h2>
              <div className="description">
                <div className={claseText}>{colorData.description()}</div>
              </div>
              <div className="doodleContainer">
                {colorData.white ? (
                  <WorldDoodle className="doodle" />
                ) : (
                  <WorldDoodleBlack className="doodle" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderTarjeta();
};
