import React from "react";
import images from "../../helpers/images";
import { ReactComponent as WorldDoodle } from "../../assets/worldDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import { ReactComponent as ColorDoodle } from "../../assets/colorDoodle.svg";
import { ReactComponent as ColorDoodleWhite } from "../../assets/colorDoodleWhite.svg";
import { ReactComponent as ShieldDoodle } from "../../assets/especificaciones/doodles/shieldDOodle.svg";
import classNames from "classnames";
import "./tarjetaColor.css";

export const TarjetaColor = ({ colorData }) => {
  const claseDatos = classNames({
    datos: true,
    borderColorBlanco: colorData.white,
  });

  const claseText = classNames({
    colorBlanco: colorData.white,
    borderColorBlanco: colorData.white,
  });

  const renderTarjeta = () => {
    if (colorData.design === "primary") {
      return (
        <div className="primary">
          <div className="tarjetaColor">
            <div
              style={{ backgroundImage: `url(${images[colorData.imagen]})` }}
              alt="color"
              className="backgroundColor"
            />
            <div className="content">
              <div className="title">
                <h1 className={claseText}>//{colorData.title}</h1>
              </div>
              <div className={claseDatos}>
                <h2 className={claseText}>{colorData.subtitle}</h2>
                <h2 className={claseText}>{colorData.year}</h2>
                <div className="description">
                  <div className={claseText}>{colorData.description()}</div>
                </div>
                <div className="doodleContainer">
                  {colorData.white ? (
                    <WorldDoodle className="doodle" />
                  ) : (
                    <WorldDoodleBlack className="doodle" />
                  )}
                </div>
              </div>
              {colorData.white ? (
                <ColorDoodleWhite className="colorDoodle" alt="color doodle" />
              ) : (
                <ColorDoodle className="colorDoodle" alt="color doodle" />
              )}
            </div>
          </div>
        </div>
      );
    } else if (colorData.design === "secondary") {
      return (
        <div className="secondary">
          <div className="tarjetaColor">
            <div
              style={{ backgroundImage: `url(${images[colorData.imagen]})` }}
              alt="color"
              className="backgroundColor"
            />
            <div className="content">
              <div className={claseDatos}>
                <h2 className={claseText}>{colorData.subtitle}</h2>
                <div className="year">
                  <h2 className={claseText}>{colorData.year}</h2>
                </div>
                <div className="description">
                  <div className={claseText}>{colorData.description()}</div>
                </div>
                <div className="doodleContainer">
                  <ShieldDoodle className="doodle" />
                </div>
              </div>
              <div className="title">
                <h1 className={claseText}>{colorData.title}</h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderTarjeta();
};
