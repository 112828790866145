import React from "react";
import classNames from "classnames";
import "./formTextboxInput.css";

export const FormTextboxInput = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  inputError = "",
}) => {

  const inputClassname = classNames({
    textbox: true,
    error: inputError !== "",
  });
  const errorTextClassname = classNames({
    errorText: true,
    showError: inputError !== "",
  })

  return (
    <div className="textboxContainer">
      <p className="label">{label}</p>
      <textarea
        name={name}
        className={inputClassname}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      ></textarea>
      <p className={errorTextClassname}>{inputError}</p>
    </div>
  );
};
