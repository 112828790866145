import React from "react";
import { Link } from "react-router-dom";
import "./homeShortSpot.css";

const HomeShortSpot = ({
  title,
  description,
  doodle,
  backgroundClass,
  baldeImg,
  especificacionId,
}) => {
  return (
    <>
      <div className="homeShortSpotContainer">
        <div className="content">
          <div className={`imagen ${backgroundClass}`}>
            <img src={doodle} className="doodle" loading="lazy" />
          </div>
          <div className="texto">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <Link
            to={`/especificaciones/${especificacionId}`}
            className="verMasButtonMobile"
          >
            Ver Mas
          </Link>
        </div>
        <Link
          to={`/especificaciones/${especificacionId}`}
          className="verMasButton"
        >
          Ver Mas
        </Link>
        <img src={baldeImg} alt="imagen balde RPT" className="balde" />
      </div>
    </>
  );
};

export default HomeShortSpot;
