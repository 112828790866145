import React from "react";
import classNames from "classnames";
import "./formTextInput.css";

export const FormTextInput = ({
  label,
  mandatory,
  name,
  onChange,
  value,
  inputError = "",
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  const inputClassname = classNames({
    input: true,
    error: inputError !== "",
  });
  const errorTextClassname = classNames({
    errorText: true,
    showError: inputError !== "",
  });

  return (
    <div className="textInputContainer">
      <p className="label">
        {label} {mandatory && "*"}
      </p>
      <input
        type="text"
        className={inputClassname}
        name={name}
        onChange={(e) => handleChange(e)}
        value={value}
      />
      <p className={errorTextClassname}>{inputError}</p>
    </div>
  );
};
