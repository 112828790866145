import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./carousel.css";

export const CarouselComponent = ({ images }) => {
  const tooglesGroupId = "Toggles";
  const valuesGroupId = "Values";
  const mainGroupId = "Main";

  //   const carouselProps = () => ({
  //     showArrows: boolean("showArrows", true, tooglesGroupId),
  //     showThumbs: boolean("showThumbs", false, tooglesGroupId),
  //     swipeable: boolean("showThumbs", false, tooglesGroupId),
  //     emulateTouch: boolean("showThumbs", false, tooglesGroupId),
  //     infiniteLoop: boolean("showThumbs", false, tooglesGroupId),
  //     selectedItem: number("selectedItem", 0, {}, valuesGroupId),
  //     autoPlay: boolean("autoPlay", true, tooglesGroupId),
  //     stopOnHover: boolean("stopOnHover", true, tooglesGroupId),
  //     interval: number("interval", 2000, valuesGroupId),
  //     transitionTime: number("showThumbs", 500, valuesGroupId),
  //     swipeScrollTolerance: number("swipeScrollTolerance", 5, {}, valuesGroupId),
  //     selectedItem: number("selectedItem", 0, {}, valuesGroupId),
  //   });
  return (
    <div className="carouselContainer">
      <Carousel
        showArrows={false}
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        interval={5000}
        transitionTime={500}
        autoPlay={true}
        stopOnHover={true}
      >
        {images.map((image) => (
          <div
            className="imgContainer"
            style={{ backgroundImage: `url(${image})` }}
          >
          </div>
        ))}
      </Carousel>
    </div>
  );
};
