import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Especificaciones } from "./pages/Especificaciones/Especificaciones";
import ColoresLatex from "./pages/ColoresLatex/ColoresLatex";
import Home from "./pages/Home/Home";
import { CotizaTuProyecto } from "./pages/CotizaTuProyecto/CotizaTuProyecto";
import { DistribuidoresFulltex } from "./pages/DistribuidoresFulltex/DistribuidoresFulltex";
import { FranquiciaFulltex } from "./pages/FranquiciaFulltex/FranquiciaFulltex";
import { ColoresRevestimiento } from "./pages/ColoresRevestimiento/ColoresRevestimiento";
import { Sucursales } from "./pages/Sucursales/Sucursales";
import { Contacto } from "./pages/Contacto/Contacto";
import { PreguntasFrecuentes } from "./pages/PreguntasFrecuentes/PreguntasFrecuentes";
import ScrollToTop from "./ScrollToTop";
import ReactLenis, { useLenis } from "@studio-freight/react-lenis";

import "./css/App.css";
import Condicional from "./helpers/Condicional";
import { Obras } from "./pages/obras/obras";

function App() {
  const location = useLocation();
  const isLocationObras = location.pathname === "/obras";
  return (
    <ReactLenis
      root
      options={{
        gestureOrientataion: "both",
        smoothWheel: true,
        normalizeWheel: true,
        duration: 1.5,
        wheelMultiplier: 1,
      }}
    >
      <div className="body">
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cotizaTuProyecto" element={<CotizaTuProyecto />} />
          <Route path="/distribuidores" element={<DistribuidoresFulltex />} />
          <Route path="/franquiciaFulltex" element={<FranquiciaFulltex />} />
          <Route path="/especificaciones/:id" element={<Especificaciones />} />
          <Route path="/coloresLatex" element={<ColoresLatex />} />
          <Route
            path="/coloresRevestimiento"
            element={<ColoresRevestimiento />}
          />
          <Route path="/sucursales" element={<Sucursales />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route
            path="/preguntasFrecuentes"
            element={<PreguntasFrecuentes />}
          />
          <Route path="/obras" element={<Obras />} />
        </Routes>
        <Condicional test={!isLocationObras}>
          <Footer />
        </Condicional>
      </div>
    </ReactLenis>
  );
}

export default App;
