import baseCoat1 from "../assets/baseCoat1.png";
import baseCoat2 from "../assets/baldes/baldeBC.webp";
import basePlastica from "../assets/baldes/baldeBP.webp";
import granito from "../assets/baldes/baldeG.webp";
import latex1 from "../assets/baldes/baldeL.webp";
import latex2 from "../assets/latex2.png";
import membranaLiquida from "../assets/membranaLiquida.png";
import revestimiento1 from "../assets/revestimiento1.png";
import revestimiento2 from "../assets/baldes/baldeRPT.webp";
import baseCoatTitleLogo from "../assets/baseCoatTitleLogo.png";
import revestimientoTitleLogo from "../assets/revestimientoTitleLogo.png";
import latexTitleLogo from "../assets/latexTitleLogo.png";
import basePlasticaTitleLogo from "../assets/basePlasticaTitleLogo.png";
import membranaLiquidaTitleLogo from "../assets/membranaLiquidaTitleLogo.png";
import granitoTitleLogo from "../assets/granitoTitleLogo.png";

import arenaClara from "../assets/webp/arenaClara.webp";
import arenaLuminoso from "../assets/webp/arenaLuminoso.webp";
import arena from "../assets/webp/arena.webp";
import arenaTM from "../assets/PNG/arenaTM.png";
import beigeAlmendra from "../assets/webp/beigeAlmendra.webp";
import beigeCaramelo from "../assets/webp/beigeCaramelo.webp";
import beigeClaro from "../assets/webp/beigeClaro.webp";
import beigeTM from "../assets/PNG/beigeTM.png";
import beigeVerdoso from "../assets/webp/beigeVerdoso.webp";
import blanco from "../assets/webp/blanco.webp";
import blancoTM from "../assets/PNG/blancoTM.png";
import blancoNieve from "../assets/webp/blancoNieve.webp";
import canela from "../assets/webp/canela.webp";
import chocolate from "../assets/webp/chocolate.webp";
import grisTM from "../assets/PNG/grisTM.png";
import grisGR from "../assets/webp/grisGR.webp";
import grisAceroTM from "../assets/PNG/grisAceroTM.png";
import grisHielo from "../assets/webp/grisHielo.webp";
import grisIntermedio from "../assets/webp/grisIntermedio.webp";
import grisMedio from "../assets/webp/grisMedio.webp";
import grisOscuro from "../assets/webp/grisOscuro.webp";
import grisPerlado from "../assets/webp/grisPerlado.webp";
import mandarina from "../assets/webp/mandarina.webp";
import marfil from "../assets/webp/marfil.webp";
import marronHabano from "../assets/webp/marronHabano.webp";
import natural from "../assets/webp/natural.webp";
import negro from "../assets/webp/negro.webp";
import negroGR from "../assets/webp/negroGR.webp";
import negroTM from "../assets/PNG/negroTM.png";
import ocre from "../assets/webp/ocre.webp";
import oliva from "../assets/webp/oliva.webp";
import rojoTeja from "../assets/webp/rojoTeja.webp";
import rojoTejaTM from "../assets/PNG/rojoTejaTM.png";
import rojo from "../assets/webp/rojo.webp";
import rosaGR from "../assets/webp/rosaGR.webp";
import rosaNuevo from "../assets/webp/rosaNuevo.webp";
import rosaOpaco from "../assets/webp/rosaOpaco.webp";
import sesamo from "../assets/webp/sesamo.webp";
import tierra from "../assets/webp/tierra.webp";
import tormenta from "../assets/webp/tormenta.webp";
import trigo from "../assets/webp/trigo.webp";
import ceniza from "../assets/webp/ceniza.webp";
import verdeMilitar from "../assets/webp/verdeMilitar.webp";
import verdeTM from "../assets/PNG/verdeTM.png";
import vison from "../assets/webp/vison.webp";
import grison from "../assets/webp/grison.webp";
import verdeClaro from "../assets/webp/verdeClaro.webp";
import camel from "../assets/webp/camel.webp";
import durazno from "../assets/webp/durazno.webp";
import francia from "../assets/webp/francia.webp";

import cardArenaClara from "../assets/card/arenaClara.webp";
import cardArenaLuminoso from "../assets/card/arenaLuminoso.webp";
import cardArena from "../assets/card/arena.webp";
import cardBeigeAlmendra from "../assets/card/beigeAlmendra.webp";
import cardBeigeCaramelo from "../assets/card/beigeCaramelo.webp";
import cardBeigeClaro from "../assets/card/beigeClaro.webp";
import cardBeigeVerdoso from "../assets/card/beigeVerdoso.webp";
import cardBlanco from "../assets/card/blanco.webp";
import cardBlancoNieve from "../assets/card/blancoNieve.webp";
import cardCanela from "../assets/card/canela.webp";
import cardChocolate from "../assets/card/chocolate.webp";
import cardGrisHielo from "../assets/card/grisHielo.webp";
import cardGrisIntermedio from "../assets/card/grisIntermedio.webp";
import cardGrisMedio from "../assets/card/grisMedio.webp";
import cardGrisOscuro from "../assets/card/grisOscuro.webp";
import cardGrisPerlado from "../assets/card/grisPerlado.webp";
import cardMandarina from "../assets/card/mandarina.webp";
import cardMarfil from "../assets/card/marfil.webp";
import cardMarronHabano from "../assets/card/marronHabano.webp";
import cardNatural from "../assets/card/natural.webp";
import cardNegro from "../assets/card/negro.webp";
import cardOcre from "../assets/card/ocre.webp";
import cardOliva from "../assets/card/oliva.webp";
import cardRojoTeja from "../assets/card/rojoTeja.webp";
import cardRojo from "../assets/card/rojo.webp";
import cardRosaNuevo from "../assets/card/rosaNuevo.webp";
import cardRosaOpaco from "../assets/card/rosaOpaco.webp";
import cardSesamo from "../assets/card/sesamo.webp";
import cardTierra from "../assets/card/tierra.webp";
import cardTormenta from "../assets/card/tormenta.webp";
import cardTrigo from "../assets/card/trigo.webp";
import cardCeniza from "../assets/card/ceniza.webp";
import cardVerdeMilitar from "../assets/card/verdeMilitar.webp";
import cardVison from "../assets/card/vison.webp";
import cardGrison from "../assets/card/grison.webp";
import cardVerdeClaro from "../assets/card/verdeClaro.webp";
import cardCamel from "../assets/card/camel.webp";
import cardDurazno from "../assets/card/durazno.webp";
import cardFrancia from "../assets/card/francia.webp";
export default {
  baseCoat1,
  baseCoat2,
  basePlastica,
  granito,
  latex1,
  latex2,
  membranaLiquida,
  revestimiento1,
  revestimiento2,

  baseCoatTitleLogo,
  revestimientoTitleLogo,
  latexTitleLogo,
  basePlasticaTitleLogo,
  membranaLiquidaTitleLogo,
  granitoTitleLogo,

  arenaTM,
  arenaClara,
  arenaLuminoso,
  arena,
  beigeAlmendra,
  beigeCaramelo,
  beigeClaro,
  beigeTM,
  beigeVerdoso,
  blanco,
  blancoTM,
  blancoNieve,
  canela,
  chocolate,
  grisTM,
  grisGR,
  grisAceroTM,
  grisHielo,
  grisIntermedio,
  grisMedio,
  grisOscuro,
  grisPerlado,
  mandarina,
  marfil,
  marronHabano,
  natural,
  negro,
  negroGR,
  negroTM,
  ocre,
  oliva,
  rojoTeja,
  rojoTejaTM,
  rojo,
  rosaGR,
  rosaNuevo,
  rosaOpaco,
  sesamo,
  tierra,
  tormenta,
  trigo,
  ceniza,
  verdeMilitar,
  verdeTM,
  vison,
  grison,
  verdeClaro,
  camel,
  durazno,
  francia,

  cardArenaClara,
  cardArenaLuminoso,
  cardArena,
  cardBeigeAlmendra,
  cardBeigeCaramelo,
  cardBeigeClaro,
  cardBeigeVerdoso,
  cardBlanco,
  cardBlancoNieve,
  cardCanela,
  cardChocolate,
  cardGrisHielo,
  cardGrisIntermedio,
  cardGrisMedio,
  cardGrisOscuro,
  cardGrisPerlado,
  cardMandarina,
  cardMarfil,
  cardMarronHabano,
  cardNatural,
  cardNegro,
  cardOcre,
  cardOliva,
  cardRojoTeja,
  cardRojo,
  cardRosaNuevo,
  cardRosaOpaco,
  cardSesamo,
  cardTierra,
  cardTormenta,
  cardTrigo,
  cardCeniza,
  cardVerdeMilitar,
  cardVison,
  cardGrison,
  cardVerdeClaro,
  cardCamel,
  cardDurazno,
  cardFrancia,
};
