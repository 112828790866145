import React from "react";
import { ReactComponent as SlashDoodle } from "../../assets/slashDoodle.svg";
import classNames from "classnames";
import "./formCheckboxInput.css";

export const FormCheckboxInput = ({
  label,
  items,
  onChange,
  name,
  checked,
  inputError = "",
}) => {
  const errorTextClassname = classNames({
    errorText: true,
    showError: inputError !== "",
  });

  const handleClickCheckbox = (idx) => {
    if (checked === idx) {
      onChange();
    } else {
      onChange(idx);
    }
  };

  return (
    <div className="checkboxInputContainer">
      <div className="label">
        <p>{label}</p>
        <SlashDoodle className="doodle" />
      </div>
      <div className="checkboxesContainer">
        {items.map((item, idx) => (
          <div className="checkbox">
            <input
              name={name}
              checked={checked === idx}
              onClick={(event) => handleClickCheckbox(idx)}
              type="radio"
            />
            <p>{item}</p>
          </div>
        ))}
      </div>
      <p className={errorTextClassname}>{inputError}</p>
    </div>
  );
};
