import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FormTextInput } from "../../components/FormTextInput/FormTextInput";
import { CarouselComponent } from "../../components/Carousel/Carousel";
import { FormTextboxInput } from "../../components/FormTextboxInput/FormTextboxInput";
import { ReactComponent as DistribuidoresDoodle } from "../../assets/distribuidoresDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import CarouselImage1 from "../../assets/carousel1.webp";
import CarouselImage2 from "../../assets/carousel2.webp";
import CarouselImage3 from "../../assets/carousel3.webp";
import { validator } from "../../helpers/utils";
import "./distribuidoresFulltex.css";
import "react-toastify/dist/ReactToastify.css";

export const DistribuidoresFulltex = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [localidadError, setLocalidadError] = useState("");
  const [asunto, setAsunto] = useState("");
  const [asuntoError, setAsuntoError] = useState("");
  const [comentario, setComentario] = useState("");
  const [comentariosError, setComentariosError] = useState("");

  const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3];

  const validateInputs = () => {
    let isValid = true;
    if (validator(name, "name", setNameError, true)) isValid = false;
    if (validator(phone, "phone", setPhoneError, true)) isValid = false;
    if (validator(email, "email", setEmailError, true)) isValid = false;
    if (validator(localidad, "text", setLocalidadError, true)) isValid = false;
    if (validator(asunto, "text", setAsuntoError)) isValid = false;
    if (validator(comentario, "comment", setComentariosError)) isValid = false;
    return isValid;
  };
  const resetValues = () => {
    setName("");
    setNameError("");
    setPhone("");
    setPhoneError("");
    setEmail("");
    setEmailError("");
    setLocalidad("");
    setLocalidadError("");
    setAsunto("");
    setAsuntoError("");
    setComentario("");
    setComentariosError("");
  };

  const sendFormToBackend = () => {
    axios
      .post("https://04kz73hhq3.execute-api.sa-east-1.amazonaws.com/distribuidores", {
        nombre: name,
        telefono: phone,
        email,
        localidad,
        asunto,
        comentarios: comentario,
      })
      .then((res) => {
        toast.success("Formulario enviado");
        resetValues();
      })
      .catch((err) => {
        toast.error("Error al enviar el formulario");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      sendFormToBackend();
    }
  };

  return (
    <div className="distribuidoresFulltexContainer">
      <div className="distribuidoresInfo">
        <div className="title">
          <DistribuidoresDoodle className="doodle" />
        </div>
        <p>
          ¿Queres sumarte a la renovación y modernización de mas personas con
          proyectos increíbles? Nuestros productos son de la mas alta calidad,
          Súmate a la <span className="boldItalic">Familia!</span>
        </p>
        <div className="carouselSection">
          <CarouselComponent images={carouselImages} />
        </div>
      </div>
      <div className="distribuidoresForm">
        <div className="headingText">
          <WorldDoodleBlack className="worldDoodle" />
          <p>
            Llena nuestro formulario y nos comunicaremos lo mas pronto posible!
          </p>
        </div>
        <div className="form">
          <FormTextInput
            label={"Nombre y Apellido"}
            mandatory={true}
            onChange={setName}
            value={name}
            name={"fullName"}
            inputError={nameError}
          />
          <FormTextInput
            label={"Telefono"}
            mandatory={true}
            onChange={setPhone}
            value={phone}
            name={"phone"}
            inputError={phoneError}
          />
          <FormTextInput
            label={"Mail"}
            mandatory={true}
            onChange={setEmail}
            value={email}
            name={"email"}
            inputError={emailError}
          />
          <FormTextInput
            label={
              "¿En que localidad o Provincia quieres vender nuestros productos?"
            }
            mandatory={true}
            onChange={setLocalidad}
            value={localidad}
            name={"localidad"}
            inputError={localidadError}
          />
          <FormTextInput
            label={"Asunto"}
            mandatory={false}
            onChange={setAsunto}
            value={asunto}
            name={"asunto"}
            inputError={asuntoError}
          />
          <br />
          <FormTextboxInput
            label={"Tu Comentario"}
            value={comentario}
            onChange={setComentario}
            name={"comentarios"}
            inputError={comentariosError}
          />
          <button type="submit" className="submitButton" onClick={handleSubmit}>
            Enviar
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
    </div>
  );
};
