import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./formFileInput.css";

export const FormFileInput = ({ label, value, onChange }) => {
  const removeImage = (index) => {
    const newImages = [...value];
    newImages.splice(index, 1);
    onChange(newImages);
  };

  const renderImageNames = () => {
    return value.map((image, index) => (
      <div className="imageContainer" key={index}>
        <p className="imageName">{image.name}</p>
        <button
          className="removeImageButton"
          onClick={() => removeImage(index)}
        >
          <FontAwesomeIcon className="removeIcon" icon={faXmark} />
        </button>
      </div>
    ));
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  //make a function that takes a file and returns a base64 string

  const handleFileChange = async (event) => {
    const newImages = [...value];
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const base64 = await getBase64(file);
      newImages.push({ name: file.name, base64 });
    }
    onChange(newImages);
  };

  return (
    <div className="fileInputContainer">
      <p className="label">{label}</p>
      <div className="inputLine">
        <label for="file" className="fileInput">
          Seleccionar Archivo
        </label>
        <p className="fileCount">
          {value && value?.length > 0
            ? `${value.length} archivo/s seleccionado/s`
            : "Sin archivos seleccionados"}
        </p>
        <input
          type="file"
          className="fileInput"
          name="file"
          id="file"
          multiple
          hidden
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
        />
      </div>
      {renderImageNames()}
    </div>
  );
};
