import React, { useState, useEffect } from "react";
import baldeL from "../../assets/baldes/baldeL.webp";
import ColoresBanner from "../../assets/coloresBanner.webp";
import "./coloresLatex.css";
import "../../css/componentTransitions.css";
import { TarjetaColorOneToOne } from "../../components/TarjetaColorOneToOne/TarjetaColorOneToOne";

const colorDataArray = [
  {
    id: "01",
    color: "#505B6A",
    title: function () {
      return (
        <>
          Gris
          <br /> Oscuro
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "02",
    color: "#6B717C",
    title: function () {
      return (
        <>
          Gris
          <br /> Medio
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "03",
    color: "#ABACB4",
    title: function () {
      return (
        <>
          Gris
          <br /> Intermedio
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "04",
    color: "#C0C5CA",
    title: function () {
      return (
        <>
          Gris
          <br /> Perlado
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "05",
    color: "#CFD3D8",
    title: function () {
      return (
        <>
          Gris
          <br /> Hielo
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "06",
    color: "#9A98A0",
    title: function () {
      return <>Tormenta</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "07",
    color: "#DEDAD3",
    title: function () {
      return <>Marfil</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "08",
    color: "#E8E8E8",
    title: function () {
      return (
        <>
          Blanco
          <br /> Nieve
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "09",
    color: "#E5DAC4",
    title: function () {
      return <>Trigo</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "10",
    color: "#E1DDD6",
    title: function () {
      return (
        <>
          Arena
          <br /> Clara
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "11",
    color: "#E4DDCB",
    title: function () {
      return (
        <>
          Arena
          <br /> Luminoso
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "12",
    color: "#ECE4D2",
    title: function () {
      return <>Arena</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "13",
    color: "#CDBAB4",
    title: function () {
      return (
        <>
          Beige
          <br /> Caramelo
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "14 ",
    color: "#D3C6B7",
    title: function () {
      return (
        <>
          Beige
          <br /> Alemndra
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "15",
    color: "#C7BCAD",
    title: function () {
      return (
        <>
          Beige
          <br /> Claro
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "16 ",
    color: "#BFAE97",
    title: function () {
      return (
        <>
          Beige
          <br /> Verdoso
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "19",
    color: "#E4DFD6",
    title: function () {
      return <>Sésamo</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "20",
    color: "#C0B9B1",
    title: function () {
      return <>Ceniza</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "21",
    color: "#DCA875",
    title: function () {
      return <>Canela</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "22",
    color: "#A8988D",
    title: function () {
      return <>Tierra</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "23",
    color: "#DCA1A1",
    title: function () {
      return (
        <>
          Rosa
          <br /> Nuevo
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "24",
    color: "#D69C91",
    title: function () {
      return (
        <>
          Rosa
          <br /> Opaco
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "25",
    color: "#F7BD80",
    title: function () {
      return <>Durazno</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "26 ",
    color: "#EDD085",
    title: function () {
      return <>Ocre</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: false,
  },
  {
    id: "27",
    color: "#8B9A79",
    title: function () {
      return (
        <>
          Verde
          <br /> Claro
        </>
      );
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
  {
    id: "28",
    color: "#D78D3B",
    title: function () {
      return <>Camel</>;
    },
    subtitle: "LATEX PROFESIONAL",
    year: "2024",
    description: function () {
      return (
        <p>
          Pintura acrílica, con un fuerte poder cubritivo, apto para todo tipo
          de superficies
        </p>
      );
    },
    white: true,
  },
];

export default function Colores() {
  const [modalId, setModalId] = useState();

  useEffect(() => {
    if (modalId >= 0) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modalId]);

  return (
    <div className="coloresContainer">
      <div className="banner">
        <div className="headingBanner">
          <div
            className="backgroundBanner"
            style={{ backgroundImage: `url(${ColoresBanner})` }}
          />
          <div className="title">
            <p>
              Nuestros
              <br/>
              Colores de
              <br />
              <b>LINEA!</b>
            </p>
          </div>
          <div
            className="baldeContainer"
            style={{ backgroundImage: `url(${baldeL})` }}
          ></div>
        </div>
      </div>
      <div className="contenido">
        {colorDataArray.map((colorData, idx) => (
          <div onClick={() => setModalId(idx)}>
            <TarjetaColorOneToOne colorData={colorData} key={colorData.id} isModal={false} />
          </div>
        ))}
        <p className="texto">
          Sumérgete en el cambio
          <br  className="br"/> y la Modernización de tu Hogar
        </p>
      </div>
      {modalId >= 0 && (
        <div className="modal">
          <TarjetaColorOneToOne
            colorData={colorDataArray[modalId]}
            isModal={true}
            setModalId={setModalId}
          />
        </div>
      )}
    </div>
  );
}
