export const validator = (value, type, setter, mandatory = false) => {
  setter("");
  const invalidCharactersForEmail =
    /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  const invalidCharactersForPhone = /[^0-9]/;
  const invalidCharactersForName = /[^a-zA-Z ]/;
  const invalidCharactersForText = /[^a-zA-Z0-9 ¡! ¿?()=.,:;]/;

  if (type === "email") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    } else if (!value.match(invalidCharactersForEmail)) {
      setter("El correo electronico no es valido");
      return true;
    }
  }
  if (type === "phone") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    } else if (value.match(invalidCharactersForPhone)) {
      setter("ingrese su telefono sin espacios ni guiones");
      return true;
    }
  }
  if (type === "dni") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    } else if (value.match(invalidCharactersForPhone)) {
      setter("Ingrese su dni sin puntos ni guiones");
      return true;
    }
  }
  if (type === "name") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    } else if (value.match(invalidCharactersForName)) {
      setter("Se han ingresado caracteres invalidos");
      return true;
    }
  }
  if (type === "text") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    } else if (value.match(invalidCharactersForText)) {
      setter("Se han ingresado caracteres invalidos");
      return true;
    }
  }
  if (type === "checkbox") {
    if (value && value.length === 0 && mandatory) {
      setter("Este campo es requerido");
      return true;
    }
  }
  if (type === "comment") {
    if (value === "" && mandatory) {
      setter("Este campo es requerido");
      return true;
    }
  }
  return false;
};
