import React from "react";
import { ReactComponent as ContactoDoodle } from "../../assets/especificaciones/doodles/contactoDoodle.svg";
import { ReactComponent as FacebookDoodle } from "../../assets/especificaciones/doodles/facebookDoodle.svg";
import { ReactComponent as InstagramDoodle } from "../../assets/especificaciones/doodles/instagramDoodle.svg";
import { ReactComponent as LinkedinDoodle } from "../../assets/especificaciones/doodles/linkedinDoodle.svg";
import { ReactComponent as MailDoodle } from "../../assets/especificaciones/doodles/mailDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import { ReactComponent as PhoneDoodle } from "../../assets/especificaciones/doodles/phoneDoodle.svg";
import { ReactComponent as DoodleGroup } from "../../assets/especificaciones/doodles/doodleGroup.svg";
import "./contacto.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export const Contacto = () => {
  const position = [-34.1096722449175, -59.02477017624035]; //latitud, longitud

  return (
    <div className="contactoContainer">
      <div className="banner">
        <div className="headingBanner">
          <div className="title">
            <ContactoDoodle className="doodle" />
          </div>
          <WorldDoodleBlack className="doodleBlack" />
        </div>
      </div>
      <div className="content">
        <div className="section">
          <p className="text">
            Contáctanos para cualquier pregunta o duda. Siempre mantenemos
            abiertos nuestros canales de comunicación, incluyendo nuestras redes
            sociales.
          </p>
          <div className="socials">
            <div className="social">
              <FacebookDoodle
                className="doodle"
                onClick={() =>
                  window.open("https://www.facebook.com/FullTexRevestimientos/", "_blank")
                }
              />
              <InstagramDoodle
                className="doodle"
                onClick={() =>
                  window.open("https://www.instagram.com/fulltexrevestimientos/", "_blank")
                }
              />
              <LinkedinDoodle
                className="doodle"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/leonardo-pacheco-175912300/", "_blank")
                }
              />
            </div>
            <div className="social">
              <MailDoodle className="doodle" />
              <p>fulltexrevestimientos@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="section boxContainer">
          <div className="box">
            <p>
              <b>Empresa</b>
            </p>
            <div className="phone">
              <PhoneDoodle className="phoneDoodle" /> <p>34387 67-7088</p>
            </div>
            <div className="phone">
              <PhoneDoodle className="phoneDoodle" /> <p>34387 67-7088</p>
            </div>
          </div>
          <DoodleGroup className="doodleGroup" />
          <div className="box">
            <p>
              <b>Empresa</b>
            </p>
            <div className="phone">
              <PhoneDoodle className="phoneDoodle" /> <p>34387 67-7088</p>
            </div>
            <div className="phone">
              <PhoneDoodle className="phoneDoodle" /> <p>34387 67-7088</p>
            </div>
          </div>
        </div>
        <div className="lines">
          <div className="line1" />
          <div className="line2" />
          <div className="line3" />
        </div>
        <div className="section">
          <div className="textContainer">
            <p>
              <b>Fábrica</b>
            </p>
            <p>Bernardo irigoyen 1692, Zárate</p>
            <p>
              <b>Horarios</b>
            </p>
            <p>lunes a Viernes 08:00 - 19:00 horas</p>
          </div>
        </div>
        <div className="section mapa">
          <MapContainer
            className="mapContainer"
            center={position}
            zoom={18}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/*
          <Marker position={position} icon={icon}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
            */}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};
