import React, { useState } from "react";
import "./tabbedBox.css";
import { ReactComponent as PDFDoodle } from "../../assets/pdfDoodle.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import CatalogoPDF from "../../assets/Catalogo2024.pdf";
import cx from "classnames";

export const TabbedBox = ({ data, design }) => {
  const [activeLink, setActiveLink] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownClassnames = cx({
    dropdownContainer: true,
    open: showDropdown,
  });

  const fillLine = () => {
    const remainingLines = 6 - data.length;
    const lines = [];
    for (let i = 0; i < remainingLines; i++) {
      lines.push(<div className="line" key={i} />);
    }
    return lines;
  };

  const extraLines = fillLine();
  console.log(design);
  return (
    <>
      {design === "primary" ? (
        <>
          <div className="tabbedBoxContainer">
            <div className="navigation">
              {data.map((item) => (
                <div
                  className={activeLink === item.id ? "link active" : "link"}
                  key={item.id}
                  id={item.id}
                  onClick={() => setActiveLink(item.id)}
                >
                  <>{item.title()}</>
                </div>
              ))}
              {extraLines.map((line) => (
                <>{line}</>
              ))}
            </div>
            <div className="content">
              <p>{data[activeLink].description()}</p>
            </div>
            <a href={CatalogoPDF} download={"Catalogo2024.pdf"} target="_blank" rel="noreferrer" className="download">
              <button className="downloadPDF">
                <PDFDoodle className="pdfDoodle" />
                Descargar Ficha Tecnica
              </button>
            </a>
          </div>
          <div className="tabbedBoxContainerMobile">
            <div
              className="dropdownButton"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <p>Ver especificaciones tecnicas</p>
              {showDropdown ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </div>
            <div className={dropdownClassnames}>
              {data.map((item) => (
                <div className="link" key={item.id} id={item.id}>
                  <div className="linkContainer">{item.title()}</div>
                  <div className="content">{item.description()}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {console.log("hi")}
          <div className="tabbedBoxContainerMobile active">
            <div
              className="dropdownButton"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <p>Ver especificaciones tecnicas</p>
              {showDropdown ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </div>
            <div className={dropdownClassnames}>
              {data.map((item) => (
                <div className="link" key={item.id} id={item.id}>
                  <div className="linkContainer">{item.title()}</div>
                  <div className="content">{item.description()}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
