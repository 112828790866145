import React, { useState, useEffect, useRef } from "react";
import { WhatsappDoodle } from "../../components/WhatsappDoodle/WhatsappDoodle";
import { ReactComponent as HomeCenteredTitle } from "../../assets/homeCenteredTitle.svg";
import { ReactComponent as WorldDoodle } from "../../assets/worldDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import { ReactComponent as AtencionAlClienteDoodle } from "../../assets/atencionAlclienteDoodle.svg";
import { ReactComponent as PreguntasFrecuentesDoodle } from "../../assets/PreguntasFrecuentesDoodle.svg";
import { ReactComponent as DondeComprarDoodle } from "../../assets/dondeComprarDoodle.svg";
import { ReactComponent as ObrasDoodle } from "../../assets/obrasDoodle.svg";
import { ReactComponent as CursorDoodle } from "../../assets/cursorDoodle.svg";
import { ReactComponent as UneteALaFamiliaDoodle } from "../../assets/especificaciones/doodles/uneteALaFamiliaDoodle.svg";
import BaldeRPT from "../../assets/baldes/baldeRPT.webp";
import BaldeGranito from "../../assets/baldes/baldeG.webp";
import RevestimientoTituloDoodle from "../../assets/revestimientoTituloDoodle.svg";
import GranitoTituloDoodle from "../../assets/granitoTituloDoodle.svg";
import CarouselImage1 from "../../assets/carousel1.webp";
import CarouselImage2 from "../../assets/carousel2.webp";
import CarouselImage3 from "../../assets/carousel3.webp";
import HomeShortSpot from "../../components/HomeShortSpot/HomeShortSpot";
import { CarouselComponent } from "../../components/Carousel/Carousel";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import LocationDoodle from "../../assets/locationDoodle.svg";
import L from "leaflet";
import "./home.css";
import "leaflet/dist/leaflet.css";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Home() {
  const [scrollDir, setScrollDir] = useState("scrolling down");
  const [showWhatsappSticky, setShowWhatsappSticky] = useState(false);

  const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3];
  const position = [-34.1096722449175, -59.02477017624035]; //latitud, longitud
  const icon2 = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
  });
  const icon = new L.Icon({
    iconUrl: LocationDoodle,
    iconRetinaUrl: LocationDoodle,
    className: "leaflet-div-icon",
  });
  const whatsappStickyClassname = classNames("whatsappDoodleStickyContainer", {
    showWhatsappSticky: showWhatsappSticky,
  });
  const whatsappStickyRightClassname = classNames(
    "whatsappDoodleStickyContainerRight",
    {
      showWhatsappSticky: showWhatsappSticky,
    }
  );

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && !showWhatsappSticky) {
          console.log("The target div has left the viewport");
          setShowWhatsappSticky(true);
        } else if (entry.isIntersecting && showWhatsappSticky) {
          console.log("The target div has entered the viewport");
          setShowWhatsappSticky(false);
        }
      });
    });

    observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [showWhatsappSticky]);

  const revestimientoDescription = `El revestimiento plástico texturado es una solución
  versátil y duradera para proteger y embellecer 
  superficies exteriores e interiores. 
  Aplicado en capas, proporciona resistencia a la 
  intemperie, impermeabilización y 
  acabados decorativos. Su textura variada ofrece 
  opciones estéticas que van desde lo rústico hasta 
  lo moderno. Ideal para aplicaciones en fachadas, 
  muros, y más, este revestimiento se destaca 
  por su durabilidad y fácil mantenimiento. Reemplaza
  el revoque fino y la pintura en un solo paso`;
  const granitoDescription = `El revestimiento plástico texturado es una solución
  versátil y duradera para proteger y embellecer 
  superficies exteriores e interiores. 
  Aplicado en capas, proporciona resistencia a la 
  intemperie, impermeabilización y 
  acabados decorativos. Su textura variada ofrece 
  opciones estéticas que van desde lo rústico hasta 
  lo moderno. Ideal para aplicaciones en fachadas, 
  muros, y más, este revestimiento se destaca 
  por su durabilidad y fácil mantenimiento. Reemplaza
  el revoque fino y la pintura en un solo paso `;

  return (
    <>
      <div className="backgroundHome" />
      <div className="backgroundgradienteNegro" />
      <div className="container home">
        <div ref={targetRef} className="landingContainer">
          <div className="centeredTitleContainer">
            <div className="circle">
              <HomeCenteredTitle className="centeredTitle" />
              <div className="worldDoodleContainer">
                <p>innovación</p>
                <WorldDoodle />
                <p>modernidad</p>
              </div>
            </div>
          </div>
          <div className="subtitulo">
            <h4>Cambia tu HOGAR</h4>
            <p>
              En fulltex tenes lo mejor para tu hogar <br /> Renova tu casa de
              una mejor forma
              <br /> mas sofisticada y moderna con nuestros <br /> productos
            </p>
            <WhatsappDoodle />
          </div>
        </div>
        <div className="doodleGroupContainer">
          <Link to="/contacto">
            <AtencionAlClienteDoodle />
          </Link>
          <Link to="/preguntasFrecuentes">
            <PreguntasFrecuentesDoodle />
          </Link>
          <Link to="/sucursales">
            <DondeComprarDoodle />
          </Link>
        </div>
        <div className="quienesSomosContainer">
          <div className="section firstSection">
            <div className="side1">
              <h1>QUIENES SOMOS!</h1>
              <h2>Nuestra visión</h2>
              <p>
                Somos FullTex La experiencia, el conocimiento y las ganas nos
                trajeron hasta acá. Trabajamos para ofrecerte un producto de
                calidad, colores super modernos, precios increíbles y
                accesibles, nuestro compromiso se extiende a brindarte un
                asesoramiento personalizado para que tu experiencia sea única y
                excepcional. Bienvenidos al mundo FullTex, Tenemos un largo
                camino por recorrer juntos
              </p>
            </div>
            <div className="side2">
              <WorldDoodleBlack className="worldDoodleBlack" />
              <div className="homeImage1" alt="" />
            </div>
          </div>
          <div className="section secondSection">
            <div className="side1">
              <div className="homeImage2" alt="" />
            </div>
            <div className="side2">
              <p>
                En FullTex tenemos una visión clara de donde queremos ir,
                aprendiendo y sumando personas cálidas para seguir creciendo en
                todo este trayecto que recién comienza
              </p>
              <p>
                Queremos ofrecer el mejor servicio y producto a nuestros
                clientes, ellos son una parte muy importante, y tenemos bien en
                claro que el bienestar de su hogar es parte fundamental en la
                vida diaria de cada uno.
              </p>
              <p>
                Por eso en FullTex estamos comprometidos que nuestros productos
                son de altísima calidad para tener el mejor servicio posible.
              </p>
            </div>
          </div>
          <div className="revestimientoTituloContainer">
            <div className="revestimientoDoodle">
              <img
                src={RevestimientoTituloDoodle}
                className="svg"
                loading="lazy"
              />
            </div>
            <div className="whiteMask" />
          </div>
          <HomeShortSpot
            title={"Revestimiento Plastico texturado"}
            description={revestimientoDescription}
            doodle={RevestimientoTituloDoodle}
            baldeImg={BaldeRPT}
            backgroundClass={"negro"}
            especificacionId={"1"}
          />
          <HomeShortSpot
            title={"Granito Revestimiento texturizado"}
            description={granitoDescription}
            doodle={GranitoTituloDoodle}
            baldeImg={BaldeGranito}
            backgroundClass={"granito"}
            especificacionId={"4"}
          />
          <div className="conoceMasContainer">
            <div className="layoutWithBackground">
              <div className="textoContainer">
                <div className="texto">
                  <div className="titulo">
                    <UneteALaFamiliaDoodle className="uneteALaFamiliaDoodle" />
                  </div>
                  <p>
                    se distribuidor de nuestra marca Nuevas formas de emprender
                    y crecer con un producto de alta calidad.
                  </p>
                  <div className="button">
                    <Link to="/distribuidores">Conocé mas</Link>
                  </div>
                </div>
              </div>
              <div className="imagenContainer">
                <div className="imagen" />
              </div>
            </div>
          </div>
          <div className="obrasDoodleContainer">
            <ObrasDoodle />
          </div>
          <div className="carouselSection">
            <CarouselComponent images={carouselImages} />
          </div>
          <div className="fulltexAndWorldContainer">
            <h1 className="titulo">FULLTEX</h1>
            <WorldDoodleBlack className="worldDoodle" />
          </div>
          <div className="dondeEstamosContainer">
            <div className="centerdContent">
              <div className="lineOne">
                <CursorDoodle className="cursorDoodle" />
                <div className="textos">
                  <h2>Donde Estamos!</h2>
                  <p>Bernardo irigoyen 1692, Zárate</p>
                </div>
              </div>
              <div className="lineTwo">
                <div className="button">
                  <Link to={"/sucursales"}>Todas las sucursales</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MapContainer
          className="mapContainer"
          center={position}
          zoom={18}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/*
          <Marker position={position} icon={icon}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
            */}
        </MapContainer>
        <div
          className={whatsappStickyClassname}
          onClick={() => window.open("https://wa.me/5493487677088", "_blank")}
        >
          <div className="iconContainer">
            <FontAwesomeIcon icon={faWhatsapp} className="icon" />
          </div>
        </div>
        <div className={whatsappStickyRightClassname}>
          <p className="text">Whatsapp</p>
        </div>
      </div>
    </>
  );
}
