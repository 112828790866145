import React from "react";
import { ReactComponent as SucursalesFulltexDoodle } from "../../assets/especificaciones/doodles/sucursalesFulltexDoodle.svg";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import SucursalesImage from "../../assets/sucursalesImage1.webp";
import SucursalesImage2 from "../../assets/homeBackground1.webp";
import SucursalesImage3 from "../../assets/sucursalesImage3.webp";
import SucursalesImage4 from "../../assets/carousel3.webp";
import "./sucursales.css";
import { CardsSucursales } from "../../components/CardsSucursales/CardsSucursales";

export const Sucursales = () => {
  const sucursales = [
    {
      titulo: function () {
        return (
          <p>
            EXPRESS BARILO<b>CHE</b>
          </p>
        );
      },
      direccion: "Esandi 65, San Carlos de Bariloche, Argentina, 8400 ",
      telefonos: "0294 15-496-8149",
      email: "fulltexbariloche@gmail.com ",
      image: SucursalesImage,
      facebook: "https://www.facebook.com/FullTexExpressBariloche/",
      instagram: "https://www.instagram.com/fulltexbariloche/",
    },
    {
      titulo: function () {
        return (
          <p>
            EXPRESS ESCO<b>BAR</b>
          </p>
        );
      },
      direccion: "Av. 25 de Mayo 820, Belén de Escobar Buenos Aires",
      telefonos: "11 6246-7865",
      image: SucursalesImage2,
      facebook: "https://www.facebook.com/FullTexescobar/",
      instagram: "https://www.instagram.com/fulltexescobar/",
    },
    {
      titulo: function () {
        return (
          <p>
            EXPRESS ROBE<b>RTS</b>
          </p>
        );
      },
      direccion: "Sarmiento 1753, Roberts, Buenos Aires",
      telefonos: "2355 -458815 / 2355 - 464457",
      email: "Fulltexroberts@gmail.com",
      image: SucursalesImage3,
      facebook:
        "https://www.facebook.com/profile.php?id=100095176764840&locale=es_LA",
      instagram: "https://www.instagram.com/fulltex_roberts/",
    },
    {
      titulo: function () {
        return (
          <p>
            SHOWROOM ZAR<b>ATE</b>
          </p>
        );
      },
      direccion: "Rawson 1292, Zárate, Buenos Aires, Argentina",
      telefonos: "+549 3487 67-7088 / +549 3487 68-0332",
      email: "ventasfabrica@fulltex.com.ar",
      image: SucursalesImage4,
      facebook: "https://www.facebook.com/Fulltexzarate/",
      instagram: "https://www.instagram.com/fulltexzarate/",
    },
  ];

  return (
    <div className="sucursalesContainer">
      <div className="banner">
        <div className="headingBanner">
          <div className="title">
            <SucursalesFulltexDoodle className="doodle" />
          </div>
          <div className="texts">
            <p>
              Todos nuestros puntos de ventas estan atados directamente a la
              fabrica, esto beneficia a nuestros clientes con precios
              increibles, y con beneficios unicos.
            </p>
            <p>Si no encontras algún punto de venta, contacta con nosotros</p>
          </div>
          <WorldDoodleBlack className="doodleBlack" />
        </div>
      </div>
      <div className="content">
        {sucursales.map((sucursal) => (
          <CardsSucursales sucursal={sucursal} />
        ))}
      </div>
      <div className="distribuidores-contenedor">
        <div className="titulo-distribuidores">
          <p>
            DISTRIBUIDORES <br />
            FullTex
          </p>
        </div>
        <div className="distribuidores-lista-contenedor">
          <ul>
            <li>
              <p className="letra-grande">Gualeguaychú ER</p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 3446-532455 / 3446-501285
              </p>
            </li>
            <li>
              <p className="letra-grande">Paraná ER</p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 3434 70-2092
              </p>
            </li>
            <li>
              <p className="letra-grande">Chajari ER</p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 3456-474264 / 3456-551985
              </p>
            </li>
            <li>
              <p className="letra-grande">Lourdes cerámicos / Colon ER </p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 3447-499528 / 3447-648566
              </p>
            </li>
            <li>
              <p className="letra-grande">Rosario Santa fe </p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 341750-5873 / 34150-43939
              </p>
            </li>
            <li>
              <p className="letra-grande">Baradero / San pedro </p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 3329-530310
              </p>
            </li>
            <li>
              <p className="letra-grande">Zona Norte Buenos Aires / Bernardo</p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 11 6094-4138
              </p>
            </li>
            <li>
              <p className="letra-grande">José c. Paz / Cintia Acosta </p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 11 7148-5730
              </p>
            </li>
            <li>
              <p className="letra-grande">Pilar Buenos Aires</p>
              <p className="letra-chica">
                <strong>Teléfonos:</strong> 11 5112-6711
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
