import React from "react";
import { ReactComponent as ShopDoodle } from "../../assets/especificaciones/doodles/shopDoodle.svg";
import { ReactComponent as LogoFulltexNegro } from "../../assets/logoFulltexNegro.svg";
import { ReactComponent as FacebookDoodle } from "../../assets/especificaciones/doodles/facebookDoodle.svg";
import { ReactComponent as InstagramDoodle } from "../../assets/especificaciones/doodles/instagramDoodle.svg";
import "./cardsSucursales.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

export const CardsSucursales = ({ sucursal }) => {
  return (
    <div className="cardsSucursalesContent">
      <ShopDoodle className="doodle" />
      <LogoFulltexNegro alt="logo" className="logo" />
      <p className="title">{sucursal.titulo()}</p>
      <div className="subtitleContainer">
        <p className="subtitle">
          <b>Dirección:</b> {sucursal.direccion}
        </p>
        <p className="subtitle">
          <b>Telefonos:</b> {sucursal.telefonos}
        </p>
        {sucursal.email && (
          <p className={"subtitle"}>
            <b>Mail:</b> {sucursal?.email}
          </p>
        )}
      </div>
      <div className="socials">
        <a>
          <FacebookDoodle
            onClick={() => window.open(sucursal.facebook, "_blank")}
          />
        </a>
        <a>
          <InstagramDoodle
            onClick={() => window.open(sucursal.instagram, "_blank")}
          />
        </a>
      </div>
      <div className="imageContainer">
        <div
          className="image"
          style={{ backgroundImage: `url(${sucursal.image})` }}
          alt="image"
        />
      </div>
    </div>
  );
};
