import React from "react";
import images from "../../helpers/images";
import { ReactComponent as DoodleGroup } from "../../assets/especificaciones/doodles/doodleGroup.svg";
import { ReactComponent as DoodleGroupWhite } from "../../assets/especificaciones/doodles/doodleGroupWhite.svg";
import classNames from "classnames";
import "./tarjetaRevestimiento.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const TarjetaRevestimeinto = ({ colorData, isModal, setModalId }) => {
  const tarjetaRevestimientoClassName = classNames({
    tarjetaRevestimiento: true,
    xxl: isModal,
    white: colorData.white,
  });

  const claseText = classNames({
    colorBlanco: colorData.white,
    borderColorBlanco: colorData.white,
  });

  const renderTarjeta = () => {
    return (
      <div
        className={tarjetaRevestimientoClassName}
      >
        <img src={images[colorData.imagenCard]} alt="color" className="imagen" />
        {isModal && (
          <div className="closeContainer" onClick={() => setModalId()}>
            <FontAwesomeIcon icon={faXmark} className="close" />
          </div>
        )}
      </div>
    );
  };

  return renderTarjeta();
};
