import React, { useState, useEffect } from "react";
import { ReactComponent as ColoresDeLineaBlancoDoodle } from "../../assets/especificaciones/doodles/coloresDeLineaBlancoDoodle.svg";
import { colores } from "../../helpers/datos";
import "react-toastify/dist/ReactToastify.css";
import "./coloresRevestimiento.css";
import { TarjetaRevestimeinto } from "../../components/TarjetaRevestimiento/TarjetaRevestimiento";

export const ColoresRevestimiento = () => {
  const [modalId, setModalId] = useState();

  useEffect(() => {
    if (modalId >= 0) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [modalId]);

  return (
    <div className="coloresRevestimientoContainer">
      <div className="banner">
        <div className="headingBanner">
          <div className="title">
            <ColoresDeLineaBlancoDoodle className="doodle" />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <p className="text">
            Nuestros <b>Colores de Linea</b>, una solución de alta calidad para
            proteger y embellecer superficies tanto en interiores como
            exteriores.
          </p>
        </div>
        <div className="tarjetasContainer">
          {colores.map((color, idx) => (
            <div onClick={() => setModalId(idx)}>
              <TarjetaRevestimeinto colorData={color} isModal={false} />
            </div>
          ))}
        </div>
      </div>
      {modalId >= 0 && (
        <div className="modal">
          <TarjetaRevestimeinto
            colorData={colores[modalId]}
            isModal={true}
            setModalId={setModalId}
          />
        </div>
      )}
    </div>
  );
};
