import React, { useState } from "react";
import { ReactComponent as WorldDoodleBlack } from "../../assets/worldDoodleBlack.svg";
import { ReactComponent as PreguntasFrecuentesDoodle } from "../../assets/especificaciones/doodles/preguntasFrecuentesDoodle.svg";
import "./preguntasFrecuentes.css";

export const PreguntasFrecuentes = () => {
  const preguntasFrecuentes = [
    {
      pregunta: "¿Dónde Puedo conseguir sus productos?",
      respuesta: "En nuestros puntos de ventas FULLTEX express.",
    },
    {
      pregunta: "¿Realizan la colocación de sus productos?",
      respuesta: "Brindamos colocación con profesionales de nuestra empresa.",
    },
    {
      pregunta: "¿Cuáles son los colores de lineal?",
      respuesta:
        "Tenemos más de 30 colores de líneas, contamos con la posibilidad de hacer 100 colores más.",
    },
    {
      pregunta: "¿Cuántos kilos trae el balde de revestimiento?",
      respuesta: "El balde trae 30 kilos.",
    },
    {
      pregunta: "¿Cuántos litros traen los Fondos o bases?",
      respuesta: "Vienen en baldes de 4 litros, 10 litros y 20 litros.",
    },
    {
      pregunta:
        " ¿Cuál es el rendimiento de los baldes de 30 kilos y cuanto los tambores de 300kg?",
      respuesta: "El rendimiento es dependiendo las texturas.",
    },
    {
      pregunta: "¿Qué texturas fabrican?",
      respuesta: "Fina, Media y aplicación a Rodillo.",
    },
    {
      pregunta: "¿Es posible aplicar el producto sobre una pared con humedad?",
      respuesta: "No se recomienda si tiene humedad, deberá repararla.",
    },
    {
      pregunta:
        "¿Puedo aplicar el producto cuando esta nublado o esta pronosticado lluvia?",
      respuesta: "No, se recomienda con un pronostico  favorable día soleado.",
    },
    {
      pregunta: "¿Se puede aplicar en el piso el revestimiento?",
      respuesta: "No, sirve para paredes interiores y exteriores.",
    },
    {
      pregunta: "¿Cómo debería estar mi revoque para aplicar el revestimiento?",
      respuesta:
        "El revestimiento remplaza al revoque fino, lo que deberías de tener un revoque fratachado grueso.",
    },
    {
      pregunta:
        "Mi pared tiene grietas o esta rota ¿El revestimiento puede cubrirlas?",
      respuesta:
        "El revestimiento repara y cubre grietas, si está rota deberán repararlas.",
    },
    {
      pregunta: "¿Puedo Aplicar yo mismo el revetimiento?",
      respuesta:
        "Antes deberá tener mínimo un práctica, nuestro revestimiento deja trabajar a los primarios.",
    },
    {
      pregunta: "¿Puedo Aplicar el revestimiento cuando hay mucho sol?",
      respuesta: "Si, a temperatura de 32/35 grados.",
    },
    {
      pregunta: "¿El revestimiento Fulltex es impermeable?",
      respuesta:
        "Está formulado con resina, emulsiones acrílicas y todos los químicos que hace una mejor durabilidad e impermeabilidad a sus paredes.",
    },
  ];
  const [preguntaId, setPreguntaId] = useState(-1);

  const handlePreguntaClick = (index) => {
    if (preguntaId === index) {
      setPreguntaId(-1);
    } else {
      setPreguntaId(index);
    }
  };

  return (
    <div className="preguntasFrecuentesContainer">
      <div className="section">
        <WorldDoodleBlack className="doodleWorld" />
        <PreguntasFrecuentesDoodle className="doodle" />
      </div>
      <div className="textSection">
        <h2>Nuestro centro de ayuda</h2>
        <p>
          Aquí podrás encontrar varias preguntas que podran solucionar tus
          inquietudes sobre nuestros productos{" "}
        </p>
      </div>
      <div className="preguntasContainer">
        {preguntasFrecuentes.map((pregunta, index) => (
          <div className="pregunta" key={index}>
            <div className="row" onClick={() => handlePreguntaClick(index)}>
              <h3>{preguntaId === index ? "-" : "+"}</h3>{" "}
              <h3>{pregunta.pregunta}</h3>
            </div>
            <p className={preguntaId === index ? "open" : ""}>
              {pregunta.respuesta}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
