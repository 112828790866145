import React from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./whatsappDoodle.css";

export const WhatsappDoodle = () => {
  
  return (
    <div className="outerBorder" onClick={() => window.open("https://wa.me/5493487677088", '_blank')}>
      <div className="innerDoodle">
        <FontAwesomeIcon icon={faWhatsapp} />
        <p>Contáctanos</p>
      </div>
    </div>
  );
};
