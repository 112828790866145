import React, { useState } from "react";
import { ReactComponent as LogoFulltexNegro } from "../../assets/logoFulltexNegro.svg";
import { ReactComponent as DropdownArrowDoodle } from "../../assets/dropdownArrowDoodle.svg";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import cx from "classnames";
import "./header.css";

export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(-1);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [baldeActive, setBaldeActive] = useState(0);

  setTimeout(() => setFinishedLoading(true), 3000);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdownInMobile = () => {
    setIsNavigationOpen(false);
    setIsDropdownOpen(false);
  };

  const dropdownClassnames = cx({
    dropdownContainer: true,
    open: isDropdownOpen,
  });

  const secondDropdownClassnames = cx({
    secondDropdownContainer: true,
    open: isDropdownOpen,
  });

  const startingMobileNavigation = classNames("headerMobileInactive", {
    headerActive: !isNavigationOpen,
  });
  const endMobileNavigation = classNames("headerMobile", {
    preload: !finishedLoading,
    "slide-in": isNavigationOpen,
    "slide-out": !isNavigationOpen,
  });

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };
  setTimeout(() => setFinishedLoading(true), 3000);

  const handleNavClick = () => {
    setIsDropdownOpen(false);
    setIsSecondDropdownOpen(-1);
  };

  const toggleSecondDropdown = (index) => {
    if (isSecondDropdownOpen === index) {
      setIsSecondDropdownOpen(-1);
    } else {
      setIsSecondDropdownOpen(index);
    }
  };

  return (
    <>
      <div className="header">
        <div>
          <Link to="/">
            <LogoFulltexNegro alt="logo" className="logo" />
          </Link>
        </div>
        <div className="links">
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/">Home</Link>
          </div>
          <div className="dropdown">
            <div className="dropdownButton" onClick={() => toggleDropdown()}>
              <p>Productos</p> <DropdownArrowDoodle className="dropdownArrow" />
            </div>
            <div className={dropdownClassnames}>
              <div className="dropdownContent">
                <div
                  className="dropdownLink"
                  onClick={() => toggleSecondDropdown(0)}
                  onMouseEnter={() => setBaldeActive(0)}
                >
                  <p>Latex</p>
                </div>
                <div
                  className={
                    isSecondDropdownOpen === 0
                      ? "secondDropdownContainer open"
                      : "secondDropdownContainer"
                  }
                >
                  <div
                    className="secondDropdownLink"
                    onClick={() => toggleDropdown()}
                  >
                    <Link to="/especificaciones/0">Ficha Tecnica</Link>
                  </div>
                  <div
                    className="secondDropdownLink"
                    onClick={() => toggleDropdown()}
                  >
                    <Link to="/coloresLatex">Colores de Linea</Link>
                  </div>
                </div>
                <div
                  className="dropdownLink"
                  onClick={() => toggleSecondDropdown(1)}
                  onMouseEnter={() => setBaldeActive(1)}
                >
                  <p>Revestimiento</p>
                </div>
                <div
                  className={
                    isSecondDropdownOpen === 1
                      ? "secondDropdownContainer open"
                      : "secondDropdownContainer"
                  }
                >
                  <div
                    className="secondDropdownLink"
                    onClick={() => toggleDropdown()}
                  >
                    <Link to="/especificaciones/1">Ficha Tecnica</Link>
                  </div>
                  <div
                    className="secondDropdownLink"
                    onClick={() => toggleDropdown()}
                  >
                    <Link to="/coloresRevestimiento">Colores de Linea</Link>
                  </div>
                </div>
                <div
                  className="dropdownLink"
                  onClick={() => toggleDropdown()}
                  onMouseEnter={() => setBaldeActive(2)}
                >
                  <Link to="/especificaciones/2">Base Coat</Link>
                </div>
                <div
                  className="dropdownLink"
                  onClick={() => toggleDropdown()}
                  onMouseEnter={() => setBaldeActive(3)}
                >
                  <Link to="/especificaciones/3">Techos y Muros</Link>
                </div>
                <div
                  className="dropdownLink"
                  onClick={() => toggleDropdown()}
                  onMouseEnter={() => setBaldeActive(4)}
                >
                  <Link to="/especificaciones/4">Granito</Link>
                </div>
                <div
                  className="dropdownLink"
                  onClick={() => toggleDropdown()}
                  onMouseEnter={() => setBaldeActive(5)}
                >
                  <Link to="/especificaciones/5">Base Plastica</Link>
                </div>
              </div>
              <div className="baldeContainer">
                <div
                  className={baldeActive === 0 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeL.webp")})`,
                  }}
                />
                <div
                  className={baldeActive === 1 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeRPT.webp")})`,
                  }}
                />
                <div
                  className={baldeActive === 2 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeBC.webp")})`,
                  }}
                />
                <div
                  className={baldeActive === 3 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeTM.webp")})`,
                  }}
                />
                <div
                  className={baldeActive === 4 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeG.webp")})`,
                  }}
                />
                <div
                  className={baldeActive === 5 ? "balde active" : "balde"}
                  style={{
                    backgroundImage: `url(${require("../../assets/baldes/baldeBP.webp")})`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/sucursales">Sucursales</Link>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/obras">Obras</Link>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/cotizaTuProyecto">Cotiza tu Proyecto</Link>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/distribuidores">Distribuidores</Link>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/franquiciaFulltex">Franquicia Fulltex</Link>
          </div>
          <div className="link" onClick={() => handleNavClick()}>
            <Link to="/contacto">Contacto</Link>
          </div>
        </div>
      </div>
      <div className={startingMobileNavigation}>
        <div className="logoContainer">
          <Link to="/">
            <LogoFulltexNegro alt="logo" className="logo" />
          </Link>
        </div>
        <div className="links">
          <div onClick={() => setIsNavigationOpen(true)}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </div>
      <div className={endMobileNavigation}>
        <div className="logo">
          <div
            className="img"
            onClick={() => {
              setIsNavigationOpen(false);
              routeChange();
            }}
          />
          <div
            className="closeNavigation"
            onClick={() => setIsNavigationOpen(false)}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/">Home</Link>
        </div>
        <div onClick={() => toggleDropdown()} className="link">
          <p>Productos</p>
        </div>
        <ul className={dropdownClassnames}>
          <li onClick={() => toggleSecondDropdown(0)}>
            <p>Latex</p>
          </li>
          <ul
            className={
              isSecondDropdownOpen === 0
                ? "secondDropdown open"
                : "secondDropdown"
            }
          >
            <li onClick={() => toggleDropdownInMobile()}>
              <Link to="/especificaciones/0">Ficha Tecnica</Link>
            </li>
            <li onClick={() => toggleDropdownInMobile()}>
              <Link to="/coloresLatex">Colores de Linea</Link>
            </li>
          </ul>
          <li onClick={() => toggleSecondDropdown(1)}>
            <p>Revestimiento</p>
          </li>
          <ul
            className={
              isSecondDropdownOpen === 1
                ? "secondDropdown open"
                : "secondDropdown"
            }
          >
            <li onClick={() => toggleDropdownInMobile()}>
              <Link to="/especificaciones/1">Ficha Tecnica</Link>
            </li>
            <li onClick={() => toggleDropdownInMobile()}>
              <Link to="/coloresRevestimiento">Colores de Linea</Link>
            </li>
          </ul>
          <li onClick={() => toggleDropdownInMobile()}>
            <Link to="/especificaciones/2">Base Coat</Link>
          </li>
          <li onClick={() => toggleDropdownInMobile()}>
            <Link to="/especificaciones/3">Techos y Muros</Link>
          </li>
          <li onClick={() => toggleDropdownInMobile()}>
            <Link to="/especificaciones/4">Granito</Link>
          </li>
          <li onClick={() => toggleDropdownInMobile()}>
            <Link to="/especificaciones/5">Base Plastica</Link>
          </li>
        </ul>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/sucursales">Sucursales</Link>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/obras">Obras</Link>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/cotizaTuProyecto">Cotiza tu Proyecto</Link>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/distribuidores">Distribuidores</Link>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/franquiciaFulltex">Franquicia Fulltex</Link>
        </div>
        <div onClick={() => setIsNavigationOpen(false)} className="link">
          <Link to="/contacto">Contacto</Link>
        </div>
        <div className="copywright">
          <p>Copyright @ NodeStudio 2023</p>
        </div>
      </div>
    </>
  );
}
